import React from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AppBar from "@material-ui/core/AppBar";
import Avatar from "@material-ui/core/Avatar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { BELOW_THE_HEADER, COLLAPSED_DRAWER, FIXED_DRAWER, HORIZONTAL_NAVIGATION, INSIDE_THE_HEADER } from "../../actions/types";
import { switchLanguage, toggleCollapsedNav } from "../../actions/SettingActions";
import LanguageSwitcher from "./LanguageSwitcher/index";
import languageData from "./LanguageSwitcher/data";
import Menu from "./TopNav/Menu";
import UserInfoPopup from "./UserInfo/UserInfoPopup";
import IntlMessages from "util/IntlMessages";

class Header extends React.Component {
	onLangSwitcherSelect = event => {
		this.setState({
			langSwitcher: !this.state.langSwitcher,
			anchorEl: event.currentTarget
		});
	};
	onUserInfoSelect = () => {
		this.setState({
			userInfo: !this.state.userInfo
		});
	};
	handleRequestClose = () => {
		this.setState({
			langSwitcher: false,
			userInfo: false
		});
	};
	onToggleCollapsedNav = e => {
		const val = !this.props.navCollapsed;
		this.props.toggleCollapsedNav(val);
	};

	constructor() {
		super();
		this.state = {
			anchorEl: undefined,
			userInfo: false,
			langSwitcher: false
		};
	}

	render() {
		const { history, drawerType, locale, navigationStyle, horizontalNavPosition, authUser } = this.props;
		const drawerStyle = drawerType.includes(FIXED_DRAWER)
			? "d-block d-xl-none"
			: drawerType.includes(COLLAPSED_DRAWER)
				? "d-block"
				: "d-none";

		return (
			<AppBar
				className={`app-main-header ${navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === BELOW_THE_HEADER
					? "app-main-header-top"
					: ""
					}`}
			>
				<Toolbar className="app-toolbar" disableGutters={false}>
					{navigationStyle === HORIZONTAL_NAVIGATION ? (
						<div className="d-block d-md-none pointer mr-3" onClick={this.onToggleCollapsedNav}>
							<span className="jr-menu-icon">
								<span className="menu-icon" />
							</span>
						</div>
					) : (
						<IconButton
							className={`jr-menu-icon mr-3 ${drawerStyle}`}
							aria-label="Menu"
							onClick={this.onToggleCollapsedNav}
						>
							<span className="menu-icon" />
						</IconButton>
					)}

					<div>

						<IntlMessages id="signin.welcome" />
					</div>



					{navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === INSIDE_THE_HEADER && <Menu />}

					<ul className="header-notifications list-inline ml-auto">
						<li className="list-inline-item">
							<Dropdown
								className="quick-menu"
								isOpen={this.state.langSwitcher}
								toggle={this.onLangSwitcherSelect.bind(this)}
							>
								{languageData.map((language, index) => (
									<DropdownToggle key={index} className={`d-inline-block ${locale.locale === language.locale ? "bg-gradient-grey-no" : ""}`} tag="span" onClick={() => {
										this.props.switchLanguage(language)
									}}  >
										<IconButton className="icon-btn ">
											<i className={`flag flag-24 flag-${language.icon}`} />
										</IconButton>
									</DropdownToggle>
								))}
							</Dropdown>
						</li>

						{navigationStyle === HORIZONTAL_NAVIGATION && (
							<li className="list-inline-item user-nav">
								<Dropdown
									className="quick-menu"
									isOpen={this.state.userInfo}
									toggle={this.onUserInfoSelect.bind(this)}
								>
									<DropdownToggle className="d-inline-block" tag="span" data-toggle="dropdown">
										<IconButton className="icon-btn size-30">
											<Avatar alt="..." src={"https://via.placeholder.com/150x150"} className="size-30" />
										</IconButton>
									</DropdownToggle>

									<DropdownMenu right>
										<UserInfoPopup />
									</DropdownMenu>
								</Dropdown>
							</li>
						)}
					</ul>

					<div className="ellipse-shape" />
				</Toolbar>
			</AppBar>
		);
	}
}

const mapStateToProps = ({ setting, auth: { authUser } }) => {
	const { drawerType, locale, navigationStyle, horizontalNavPosition } = setting;
	return { drawerType, locale, navigationStyle, horizontalNavPosition, authUser };
};

export default withRouter(
	connect(
		mapStateToProps,
		{ toggleCollapsedNav, switchLanguage }
	)(Header)
);
