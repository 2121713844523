export const ADMIN_TYPES = ['csn'];
export const USER_TYPES = ["csn", "nissan", "nml"];
export const REGION_MANAGER_TYPES = ["region_manager"];
export const AREA_MANAGER_TYPES = ['area_manager'];
export const DEALER_TYPES = ["dealer"];
export const CALLCENTER_TYPES = ['callcenter'];
export const DEALER_MANAGEMENT_LEVEL = ["กรรมการผู้จัดการ", "ผู้จัดการ"];
export const TICKET_EDIT_USER_TYPES = ["csn", "nissan"];
export const TICKET_MANAGEMENT_USER_TYPES = ["csn", "nissan"];
export const TICKET_TYPES = ["bp"];
export const TICKET_BP_TYPE = "bp";
export const TICKET_BP_STATUSES = ["W", "C", "S", "T", "B"];
export const TICKET_STATUSES = ["W", "C", "S", "T", "B"];
export const TICKET_W_STATUS = "W";
export const TICKET_C_STATUS = "C";
export const TICKET_S_STATUS = "S";
export const TICKET_T_STATUS = "T";
export const TICKET_B_STATUS = "B";
export const TICKET_D_STATUS = "D";
export const TICKET_SOURCES = ["survey"];
export const TICKET_SURVEY_SOURCE = "survey";
export const TICKET_CALLCENTER_SOURCE = "callcenter";
export const TICKET_CLOSED_MONTHS = 1;
export const TICKET_CLOSED_DATES = 20;
export const STRING_TRIM_LENGTH = 80;

export const PERMISSION_LOGIN = [
	"ลูกค้าสัมพันธ์ฝ่ายบริการ",
	"เจ้าหน้าที่รับรถประเมินราคาสีและตัวถัง",
	"ผู้จัดการศูนย์บริการ/ผู้จัดการศูนย์สีและตัวถัง",
	"ผู้จัดการรับรถประเมินราคาสีและตัวถัง",
	"รักษาการผู้จัดการสีและตัวถัง/ผู้ช่วยผู้จัดการ",
	"ผู้จัดการสีและตัวถัง",
	"กรรมการผู้จัดการ"
];

export const PERMISSION_TICKET_BP_VIEW = [
	"ลูกค้าสัมพันธ์ฝ่ายบริการ",
	"เจ้าหน้าที่รับรถประเมินราคาสีและตัวถัง",
	"ผู้จัดการศูนย์บริการ/ผู้จัดการศูนย์สีและตัวถัง",
	"ผู้จัดการรับรถประเมินราคาสีและตัวถัง",
	"รักษาการผู้จัดการสีและตัวถัง/ผู้ช่วยผู้จัดการ",
	"ผู้จัดการสีและตัวถัง",
	"กรรมการผู้จัดการ"
];

export const PERMISSION_GENERAL_REPORT = [
	"ลูกค้าสัมพันธ์ฝ่ายบริการ",
	"เจ้าหน้าที่รับรถประเมินราคาสีและตัวถัง",
	"ผู้จัดการศูนย์บริการ/ผู้จัดการศูนย์สีและตัวถัง",
	"ผู้จัดการรับรถประเมินราคาสีและตัวถัง",
	"รักษาการผู้จัดการสีและตัวถัง/ผู้ช่วยผู้จัดการ",
	"ผู้จัดการสีและตัวถัง",
	"กรรมการผู้จัดการ"
];

export const PERMISSION_MANAGEMENT_REPORT = [
	"ลูกค้าสัมพันธ์ฝ่ายบริการ",
	"เจ้าหน้าที่รับรถประเมินราคาสีและตัวถัง",
	"ผู้จัดการศูนย์บริการ/ผู้จัดการศูนย์สีและตัวถัง",
	"ผู้จัดการรับรถประเมินราคาสีและตัวถัง",
	"รักษาการผู้จัดการสีและตัวถัง/ผู้ช่วยผู้จัดการ",
	"ผู้จัดการสีและตัวถัง",
	"กรรมการผู้จัดการ"
];

export const PERMISSION_TICKET_EDIT = [
	"ลูกค้าสัมพันธ์ฝ่ายบริการ",
	"เจ้าหน้าที่รับรถประเมินราคาสีและตัวถัง",
	"ผู้จัดการศูนย์บริการ/ผู้จัดการศูนย์สีและตัวถัง",
	"ผู้จัดการรับรถประเมินราคาสีและตัวถัง",
	"รักษาการผู้จัดการสีและตัวถัง/ผู้ช่วยผู้จัดการ",
	"ผู้จัดการสีและตัวถัง",
	"กรรมการผู้จัดการ"
];

export const PERMISSION_TICKET_MANAGEMENT_EDIT = [
	"ผู้จัดการศูนย์บริการ/ผู้จัดการศูนย์สีและตัวถัง",
	"ผู้จัดการรับรถประเมินราคาสีและตัวถัง",
	"รักษาการผู้จัดการสีและตัวถัง/ผู้ช่วยผู้จัดการ",
	"ผู้จัดการสีและตัวถัง",
	"กรรมการผู้จัดการ"
];

export const PERMISSION_MULTI_BRANCHES_EDIT = ["กรรมการผู้จัดการ"];

export const SHOWROOM_MANAGER_TYPE = "ผู้จัดการขาย";
export const SERVICE_MANAGER_TYPE = "ผู้จัดการศูนย์บริการ";
export const BP_MANAGER_TYPE = "ผู้จัดการสีและตัวถัง";
