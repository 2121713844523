import {
    REPORT_RESPONSE_RATE_FETCH,
    REPORT_RESPONSE_RATE_FETCH_SUCCESS,
    REPORT_RESPONSE_RATE_FETCH_RESET,
    REPORT_RESPONSE_RATE_DAILY_FETCH,
    REPORT_RESPONSE_RATE_DAILY_FETCH_SUCCESS,
    REPORT_RESPONSE_RATE_DAILY_FETCH_RESET,
    REPORT_SUMMARY_DEALER_FETCH,
    REPORT_SUMMARY_DEALER_FETCH_SUCCESS,
    REPORT_SUMMARY_DEALER_FETCH_RESET,
    REPORT_SUMMARY_FETCH,
    REPORT_SUMMARY_FETCH_SUCCESS,
    REPORT_SUMMARY_FETCH_RESET,
    REPORT_CUSTOMER_SURVEY_DETAIL_FETCH,
    REPORT_CUSTOMER_SURVEY_DETAIL_FETCH_SUCCESS,
    REPORT_CUSTOMER_SURVEY_DETAIL_FETCH_RESET,
    REPORT_CUSTOMER_SURVEY_DETAIL_NOTEPAD_FETCH,
    REPORT_CUSTOMER_SURVEY_DETAIL_NOTEPAD_FETCH_SUCCESS,
    REPORT_CUSTOMER_SURVEY_DETAIL_NOTEPAD_FETCH_RESET,
} from "./types";

export const reportResponseRateFetch = payload => {
    return {
        type: REPORT_RESPONSE_RATE_FETCH,
        payload: payload
    };
}

export const reportResponseRateFetchSuccess = payload => {
    return {
        type: REPORT_RESPONSE_RATE_FETCH_SUCCESS,
        payload: payload
    };
}

export const reportResponseRateFetchReset = () => {
    return {
        type: REPORT_RESPONSE_RATE_FETCH_RESET
    };
}

export const reportResponseRateDailyFetch = payload => {
    return {
        type: REPORT_RESPONSE_RATE_DAILY_FETCH,
        payload: payload
    };
}

export const reportResponseRateDailyFetchSuccess = payload => {
    return {
        type: REPORT_RESPONSE_RATE_DAILY_FETCH_SUCCESS,
        payload: payload
    };
}

export const reportResponseRateDailyFetchReset = () => {
    return {
        type: REPORT_RESPONSE_RATE_DAILY_FETCH_RESET
    };
}

export const reportSummaryDealerFetch = payload => {
    return {
        type: REPORT_SUMMARY_DEALER_FETCH,
        payload: payload
    };
}

export const reportSummaryDealerFetchSuccess = payload => {
    return {
        type: REPORT_SUMMARY_DEALER_FETCH_SUCCESS,
        payload: payload
    };
}

export const reportSummaryDealerFetchReset = () => {
    return {
        type: REPORT_SUMMARY_DEALER_FETCH_RESET
    };
}

export const reportSummaryFetch = payload => {
    return {
        type: REPORT_SUMMARY_FETCH,
        payload: payload
    };
}

export const reportSummaryFetchSuccess = payload => {
    return {
        type: REPORT_SUMMARY_FETCH_SUCCESS,
        payload: payload
    };
}

export const reportSummaryFetchReset = () => {
    return {
        type: REPORT_SUMMARY_FETCH_RESET
    };
}

export const reportCustomerSurveyDetailFetch = payload => {
    return {
        type: REPORT_CUSTOMER_SURVEY_DETAIL_FETCH,
        payload: payload
    };
}

export const reportCustomerSurveyDetailFetchSuccess = payload => {
    return {
        type: REPORT_CUSTOMER_SURVEY_DETAIL_FETCH_SUCCESS,
        payload: payload
    };
}

export const reportCustomerSurveyDetailFetchReset = () => {
    return {
        type: REPORT_CUSTOMER_SURVEY_DETAIL_FETCH_RESET
    };
}

export const reportCustomerSurveyDetailNotepadFetch = payload => {
    return {
        type: REPORT_CUSTOMER_SURVEY_DETAIL_NOTEPAD_FETCH,
        payload: payload
    };
}

export const reportCustomerSurveyDetailNotepadFetchSuccess = payload => {
    return {
        type: REPORT_CUSTOMER_SURVEY_DETAIL_NOTEPAD_FETCH_SUCCESS,
        payload: payload
    };
}

export const reportCustomerSurveyDetailNotepadFetchReset = () => {
    return {
        type: REPORT_CUSTOMER_SURVEY_DETAIL_NOTEPAD_FETCH_RESET
    };
}