import {
	DEALER_FETCH,
	DEALER_FETCH_SUCCESS,
	DEALERS_FETCH,
	DEALERS_FETCH_SUCCESS,
	DEALER_PROVINCES_FETCH,
	DEALER_PROVINCES_FETCH_SUCCESS,
	DEALER_PROVINCES_FETCH_EN_SUCCESS,
	DEALER_CREATE,
	DEALER_UPDATE,
	DEALER_DELETE,
	DEALERS_FETCH_RESET,
	DEALER_FETCH_RESET,
	DEALER_PROVINCES_FETCH_RESET
} from "./types";

export const dealerFetch = id => {
	return {
		type: DEALER_FETCH,
		payload: id
	};
};

export const dealerFetchSuccess = payload => {
	return {
		type: DEALER_FETCH_SUCCESS,
		payload: payload
	};
};

export const dealerFetchReset = () => {
	return {
		type: DEALER_FETCH_RESET
	};
};

export const dealersFetch = payload => {
	return {
		type: DEALERS_FETCH,
		payload: payload
	};
};

export const dealersFetchSuccess = payload => {
	return {
		type: DEALERS_FETCH_SUCCESS,
		payload: payload
	};
};

export const dealersFetchReset = () => {
	return {
		type: DEALERS_FETCH_RESET
	};
};

export const dealerProvincesFetch = payload => {
	return {
		type: DEALER_PROVINCES_FETCH,
		payload: payload
	};
};

export const dealerProvincesFetchSuccess = payload => {
	return {
		type: DEALER_PROVINCES_FETCH_SUCCESS,
		payload: payload
	};
};

export const dealerProvincesFetchEnSuccess = payload => {
	return {
		type: DEALER_PROVINCES_FETCH_EN_SUCCESS,
		payload: payload
	};
};

export const dealerProvincesFetchReset = () => {
	return {
		type: DEALER_PROVINCES_FETCH_RESET
	};
};

export const dealerCreate = values => {
	return {
		type: DEALER_CREATE,
		payload: values
	};
};

export const dealerUpdate = values => {
	return {
		type: DEALER_UPDATE,
		payload: values
	};
};

export const dealerDelete = id => {
	return {
		type: DEALER_DELETE,
		payload: id
	};
};
