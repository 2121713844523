import {
	TICKET_FETCH,
	TICKET_FETCH_SUCCESS,
	TICKET_FETCH_RESET,
	TICKET_BP_UPDATE,
	TICKETS_BP_FETCH,
	TICKETS_BP_FETCH_SUCCESS,
	TICKETS_BP_FETCH_RESET,
	TICKETS_BP_REPORT_STATUS_FETCH,
	TICKETS_BP_REPORT_STATUS_FETCH_SUCCESS,
	TICKETS_BP_REPORT_STATUS_FETCH_RESET,
	TICKETS_BP_EXPORT_EXCEL,
} from "./types";

export const ticketFetch = id => {
	return {
		type: TICKET_FETCH,
		payload: id
	};
};

export const ticketFetchSuccess = payload => {
	return {
		type: TICKET_FETCH_SUCCESS,
		payload: payload
	};
};


export const ticketFetchReset = () => {
	return {
		type: TICKET_FETCH_RESET
	};
};

export const ticketBpUpdate = values => {
	return {
		type: TICKET_BP_UPDATE,
		payload: values
	};
};

export const ticketsBpFetch = payload => {
	return {
		type: TICKETS_BP_FETCH,
		payload: payload
	};
};

export const ticketsBpFetchSuccess = payload => {
	return {
		type: TICKETS_BP_FETCH_SUCCESS,
		payload: payload
	};
};

export const ticketsBpFetchReset = () => {
	return {
		type: TICKETS_BP_FETCH_RESET
	};
};

export const ticketsBpReportStatusFetch = payload => {
	return {
		type: TICKETS_BP_REPORT_STATUS_FETCH,
		payload: payload
	};
};

export const ticketsBpReportStatusFetchSuccess = payload => {
	return {
		type: TICKETS_BP_REPORT_STATUS_FETCH_SUCCESS,
		payload: payload
	};
};

export const ticketsBpReportStatusFetchReset = () => {
	return {
		type: TICKETS_BP_REPORT_STATUS_FETCH_RESET
	};
};

export const ticketsBpExportExcel = payload => {
	return {
		type: TICKETS_BP_EXPORT_EXCEL,
		payload: payload
	};
};
