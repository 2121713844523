import {
	INIT_URL,
	USER_SIGNIN,
	USER_SIGNIN_SUCCESS,
	USER_SIGNOUT,
	USER_SIGNOUT_SUCCESS,
	USER_PROFILE_FETCH,
	USER_PROFILE_FETCH_SUCCESS,
	DEALER_SIGNIN,
	DEALER_SIGNIN_SUCCESS,
	DEALER_SIGNOUT,
	DEALER_SIGNOUT_SUCCESS
} from "./types";

export const userSignIn = payload => {
	return {
		type: USER_SIGNIN,
		payload: payload
	};
};

export const userSignInSuccess = authUser => {
	return {
		type: USER_SIGNIN_SUCCESS,
		payload: authUser
	};
};

export const userSignOut = () => {
	return {
		type: USER_SIGNOUT
	};
};

export const userSignOutSuccess = () => {
	return {
		type: USER_SIGNOUT_SUCCESS
	};
};

export const userProfileFetch = () => {
	return {
		type: USER_PROFILE_FETCH
	};
};

export const userProfileFetchSuccess = payload => {
	return {
		type: USER_PROFILE_FETCH_SUCCESS,
		payload: payload
	}
};

export const dealerSignIn = payload => {
	return {
		type: DEALER_SIGNIN,
		payload: payload
	};
};

export const dealerSignInSuccess = payload => {
	return {
		type: DEALER_SIGNIN_SUCCESS,
		payload: payload
	};
};

export const dealerSignOut = () => {
	return {
		type: DEALER_SIGNOUT
	};
};

export const dealerSignOutSuccess = () => {
	return {
		type: DEALER_SIGNOUT_SUCCESS
	};
};

export const setInitUrl = url => {
	return {
		type: INIT_URL,
		payload: url
	};
};
