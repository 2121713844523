import {
	REPORT_RESPONSE_RATE_FETCH_SUCCESS,
	REPORT_RESPONSE_RATE_FETCH_RESET,
	REPORT_RESPONSE_RATE_DAILY_FETCH_SUCCESS,
	REPORT_RESPONSE_RATE_DAILY_FETCH_RESET,
	REPORT_SUMMARY_DEALER_FETCH_SUCCESS,
	REPORT_SUMMARY_DEALER_FETCH_RESET,
	REPORT_SUMMARY_FETCH_SUCCESS,
	REPORT_SUMMARY_FETCH_RESET,
	REPORT_CUSTOMER_SURVEY_DETAIL_FETCH_SUCCESS,
	REPORT_CUSTOMER_SURVEY_DETAIL_FETCH_RESET,
	REPORT_CUSTOMER_SURVEY_DETAIL_NOTEPAD_FETCH_SUCCESS,
	REPORT_CUSTOMER_SURVEY_DETAIL_NOTEPAD_FETCH_RESET
} from "../actions/types";

const INIT_STATE = {
	reportResponseRate: {},
	reportResponseRateDaily: {},
	reportSummaryDealer: {},
	reportSummary: {},
	reportCustomerSurveyDetail: {},
	reportCustomerSurveyDetailNotepad: {}
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case REPORT_RESPONSE_RATE_FETCH_SUCCESS:
			return {
				...state,
				reportResponseRate: action.payload
			}
		case REPORT_RESPONSE_RATE_FETCH_RESET:
			return {
				...state,
				reportResponseRate: {}
			}
		case REPORT_RESPONSE_RATE_DAILY_FETCH_SUCCESS:
			return {
				...state,
				reportResponseRateDaily: action.payload
			}
		case REPORT_RESPONSE_RATE_DAILY_FETCH_RESET:
			return {
				...state,
				reportResponseRateDaily: {}
			}
		case REPORT_SUMMARY_DEALER_FETCH_SUCCESS:
			return {
				...state,
				reportSummaryDealer: action.payload
			}
		case REPORT_SUMMARY_DEALER_FETCH_RESET:
			return {
				...state,
				reportSummaryDealer: {}
			}
		case REPORT_SUMMARY_FETCH_SUCCESS:
			return {
				...state,
				reportSummary: action.payload
			}
		case REPORT_SUMMARY_FETCH_RESET:
			return {
				...state,
				reportSummary: {}
			}
		case REPORT_CUSTOMER_SURVEY_DETAIL_FETCH_SUCCESS:
			return {
				...state,
				reportCustomerSurveyDetail: action.payload
			}
		case REPORT_CUSTOMER_SURVEY_DETAIL_FETCH_RESET:
			return {
				...state,
				reportCustomerSurveyDetail: {}
			}
		case REPORT_CUSTOMER_SURVEY_DETAIL_NOTEPAD_FETCH_SUCCESS:
			return {
				...state,
				reportCustomerSurveyDetailNotepad: action.payload
			}
		case REPORT_CUSTOMER_SURVEY_DETAIL_NOTEPAD_FETCH_RESET:
			return {
				...state,
				reportCustomerSurveyDetailNotepad: {}
			}
		default:
			return state;
	}
};
