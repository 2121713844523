import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
	DEALER_FETCH,
	DEALERS_FETCH,
	DEALER_PROVINCES_FETCH,
	DEALER_CREATE,
	DEALER_UPDATE,
	DEALER_DELETE
} from "../actions/types";
import { dealerFetchSuccess, dealersFetchSuccess, dealerProvincesFetchSuccess, dealerProvincesFetchEnSuccess } from "../actions/DealerActions";
import { showErrorMessage, showSuccessMessage } from "../actions/NotifyActions";
import { authorizationHeader, getErrorMessage } from "../util/apiUtil";
import { intl } from "../lngProvider/IntlGlobalProvider";
import { reset } from "redux-form";
import axios from "axios";
import _ from "lodash";

// API
const dealerFetchRequest = async id =>
	await axios
		.get(`${process.env.REACT_APP_API_URL}/api/dealers/${id}`, {
			headers: authorizationHeader("bearer")
		})
		.then(response => response.data)
		.catch(error => {
			throw error;
		});

const dealersFetchRequest = async payload => {
	let url = `${process.env.REACT_APP_API_URL}/api/dealers`;
	let params = { ...payload };
	params = _.omitBy(params, _.isEmpty);

	return await axios
		.get(url, {
			headers: authorizationHeader("bearer"),
			params: params
		})
		.then(response => response.data)
		.catch(error => {
			throw error;
		});
};

const dealerProvincesFetchRequest = async payload => {
	let url = `${process.env.REACT_APP_API_URL}/api/dealers/field/dealer_province`;
	let params = { ...payload };
	params = _.omitBy(params, _.isEmpty);

	return await axios
		.get(url, {
			headers: authorizationHeader("bearer"),
			params: params
		})
		.then(response => response.data)
		.catch(error => {
			throw error;
		});
};

const dealerProvincesFetchEnRequest = async payload => {
	let url = `${process.env.REACT_APP_API_URL}/api/dealers/field/dealer_province_en`;
	let params = { ...payload };
	params = _.omitBy(params, _.isEmpty);

	return await axios
		.get(url, {
			headers: authorizationHeader("bearer"),
			params: params
		})
		.then(response => response.data)
		.catch(error => {
			throw error;
		});
};

const dealerCreateRequest = async values =>
	await axios
		.post(`${process.env.REACT_APP_API_URL}/api/dealers`, values, {
			headers: authorizationHeader("bearer")
		})
		.then(response => response.data)
		.catch(error => {
			throw error;
		});

const dealerUpdateRequest = async values =>
	await axios
		.put(`${process.env.REACT_APP_API_URL}/api/dealers/${values._id}`, values, {
			headers: authorizationHeader("bearer")
		})
		.then(response => response.data)
		.catch(error => {
			throw error;
		});

const dealerDeleteRequest = async id =>
	await axios
		.delete(`${process.env.REACT_APP_API_URL}/api/dealers/${id}`, {
			headers: authorizationHeader("bearer")
		})
		.then(response => response.data)
		.catch(error => {
			throw error;
		});

// WORKER
function* dealerFetch({ payload }) {
	try {
		const data = yield call(dealerFetchRequest, payload);
		yield put(dealerFetchSuccess(data));
	} catch (error) {
		yield put(showErrorMessage(getErrorMessage(error)));
	}
}

function* dealersFetch({ payload }) {
	try {
		const data = yield call(dealersFetchRequest, payload);
		yield put(dealersFetchSuccess(data));
	} catch (error) {
		yield put(showErrorMessage(getErrorMessage(error)));
	}
}

function* dealerProvincesFetch({ payload }) {
	try {
		const data = yield call(dealerProvincesFetchRequest, payload);
		const dataEn = yield call(dealerProvincesFetchEnRequest, payload);
		yield put(dealerProvincesFetchSuccess(data));
		yield put(dealerProvincesFetchEnSuccess(dataEn));
	} catch (error) {
		yield put(showErrorMessage(getErrorMessage(error)));
	}
}

function* dealerCreate({ payload }) {
	try {
		const data = yield call(dealerCreateRequest, payload);
		yield put(showSuccessMessage(intl.formatMessage({ id: "success.updated" })));
		yield put(reset("DealerForm"));
	} catch (error) {
		yield put(showErrorMessage(getErrorMessage(error)));
	}
}

function* dealerUpdate({ payload }) {
	try {
		const data = yield call(dealerUpdateRequest, payload);
		if (data.success) {
			yield put(dealerFetchSuccess(data.success.result));
			yield put(showSuccessMessage(intl.formatMessage({ id: "success.updated" })));
		}
	} catch (error) {
		yield put(showErrorMessage(getErrorMessage(error)));
	}
}

function* dealerDelete({ payload: { id } }) {
	try {
	} catch (error) {
		yield put(showErrorMessage(getErrorMessage(error)));
	}
}

// WATCHER
function* dealerFetchWatcher() {
	yield takeEvery(DEALER_FETCH, dealerFetch);
}

function* dealersFetchWatcher() {
	yield takeEvery(DEALERS_FETCH, dealersFetch);
}

function* dealerProvincesFetchWatcher() {
	yield takeEvery(DEALER_PROVINCES_FETCH, dealerProvincesFetch);
}

function* dealerCreateWatcher() {
	yield takeEvery(DEALER_CREATE, dealerCreate);
}

function* dealerUpdateWatcher() {
	yield takeEvery(DEALER_UPDATE, dealerUpdate);
}

function* dealerDeleteWatcher() {
	yield takeEvery(DEALER_DELETE, dealerDelete);
}

export default function* rootSaga() {
	yield all([
		fork(dealerFetchWatcher),
		fork(dealersFetchWatcher),
		fork(dealerProvincesFetchWatcher),
		fork(dealerCreateWatcher),
		fork(dealerUpdateWatcher),
		fork(dealerDeleteWatcher)
	]);
}
