import { filter } from "lodash";
import {
	TICKET_FETCH_SUCCESS,
	TICKET_FETCH_RESET,
	TICKETS_BP_FETCH_SUCCESS,
	TICKETS_BP_FETCH_RESET,
	TICKETS_BP_REPORT_STATUS_FETCH_SUCCESS,
	TICKETS_BP_REPORT_STATUS_FETCH_RESET
} from "../actions/types";

const INIT_STATE = {
	ticket: {},
	bp: [],
	bp_status: [],
	filter: false
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case TICKET_FETCH_SUCCESS: {
			return {
				...state,
				ticket: action.payload,
				filter: true
			};
		}
		case TICKET_FETCH_RESET: {
			return {
				...state,
				ticket: {},
				filter: false
			};
		}
		case TICKETS_BP_FETCH_SUCCESS: {
			return {
				...state,
				bp: action.payload,
				filter: true
			};
		}
		case TICKETS_BP_FETCH_RESET: {
			return {
				...state,
				bp: [],
				filter: false
			};
		}
		case TICKETS_BP_REPORT_STATUS_FETCH_SUCCESS: {
			return {
				...state,
				bp_status: action.payload,
				filter: true
			};
		}
		case TICKETS_BP_REPORT_STATUS_FETCH_RESET: {
			return {
				...state,
				bp_status: [],
				filter: false
			}
		}
		default:
			return state;
	}
};
