import React, { Component } from "react";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import moment from "moment";
import "moment/locale/th";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "material-ui-pickers";
import { Redirect, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { IntlProvider } from "react-intl";
import IntlGlobalProvider from "../lngProvider/IntlGlobalProvider";
import "assets/vendors/style";
import defaultTheme from "./themes/defaultTheme";
import AppLocale from "../lngProvider";
import MainApp from "app/index";
import SignIn from "./SignIn";
import RestrictedRoute from "../components/route/RestrictedRoute";
import { setInitUrl, userSignOut } from "../actions/AuthActions";
import asyncComponent from "util/asyncComponent";
import { DEALER_TYPES } from "../constants/Systems";

class App extends Component {

	setupBeforeUnloadListener = () => {
		window.addEventListener("beforeunload", (ev) => {
			const { authUser } = this.props;
			ev.preventDefault();
			if (authUser) {
				// this.props.userSignOut();
			}
		});
	};

	componentDidMount() {
		this.setupBeforeUnloadListener();
	}

	componentWillMount() {
		window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
		if (this.props.initURL === "") {
			this.props.setInitUrl(this.props.history.location.pathname);
		}
	}

	render() {
		const { match, location, locale, authUser, initURL } = this.props;
		if (location.pathname === "/") {
			if (authUser === null) {
				return <Redirect to={"/signin"} />;
			} else if (initURL === "" || initURL === "/" || initURL === "/signin" || initURL === "/signin/admin") {
				return <Redirect to={"/app/main"} />;
			} else {
				return <Redirect to={initURL} />;
			}
		}
		const applyTheme = createMuiTheme(defaultTheme);
		document.body.classList.remove("rtl");
		applyTheme.direction = "ltr";

		const currentAppLocale = AppLocale[locale.locale];
		moment.locale(locale.locale);

		return (
			<MuiThemeProvider theme={applyTheme}>
				<MuiPickersUtilsProvider utils={MomentUtils} locale={locale.locale} moment={moment}>
					<IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
						<IntlGlobalProvider>
							<div className="app-main">
								<Switch>
									<RestrictedRoute path={`${match.url}app`} authUser={authUser} component={MainApp} />
									<Route exact path="/signin" component={SignIn} />
									<Route exact path="/signin/admin" component={SignIn} />

									<Route component={asyncComponent(() => import("../components/error/Error404"))} />
								</Switch>
							</div>
						</IntlGlobalProvider>
					</IntlProvider>
				</MuiPickersUtilsProvider>
			</MuiThemeProvider>
		);
	}
}

const mapStateToProps = ({ setting, auth }) => {
	const { locale } = setting;
	const { authUser, initURL } = auth;
	return { locale, authUser, initURL };
};

export default connect(
	mapStateToProps,
	{ setInitUrl, userSignOut }
)(App);
