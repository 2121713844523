// Customizer const
export const TOGGLE_COLLAPSED_NAV = "toggle_collapse_menu";
export const DRAWER_TYPE = "drawer_type";
export const FIXED_DRAWER = "fixed_drawer";
export const COLLAPSED_DRAWER = "collapsible";
export const MINI_DRAWER = "mini_drawer";
export const THEME_COLOR = "theme_color";
export const DARK_THEME = "dark_theme";
export const WINDOW_WIDTH = "window-width";
export const SWITCH_LANGUAGE = "switch-language";
export const CHANGE_DIRECTION = "change-direction";

export const CHANGE_NAVIGATION_STYLE = "change-navigation-style";
export const HORIZONTAL_NAVIGATION = "horizontal_navigation";
export const VERTICAL_NAVIGATION = "vertical_navigation";

export const HORIZONTAL_MENU_POSITION = "horizontal_menu_position";
export const ABOVE_THE_HEADER = "above_the_header";
export const INSIDE_THE_HEADER = "inside_the_header";
export const BELOW_THE_HEADER = "below_the_header";

//Auth const
export const USER_SIGNIN = "user_signin";
export const USER_SIGNIN_SUCCESS = "user_signin_success";
export const USER_SIGNOUT = "user_signout";
export const USER_SIGNOUT_SUCCESS = "user_signout_success";
export const USER_PROFILE_FETCH = "user_profile_fetch";
export const USER_PROFILE_FETCH_SUCCESS = "user_profile_fetch_success";
export const INIT_URL = "init_url";
export const DEALER_SIGNIN = "dealer_signin";
export const DEALER_SIGNIN_SUCCESS = "dealer_signin_success";
export const DEALER_SIGNOUT = "dealer_signout";
export const DEALER_SIGNOUT_SUCCESS = "dealer_signout_success";

// User
export const USER_CREATE = "user_create";
export const USER_UPDATE = "user_update";
export const USER_DELETE = "user_delete";
export const USER_FETCH = "user_fetch";
export const USER_FETCH_SUCCESS = "user_fetch_success";
export const USER_FETCH_RESET = "user_fetch_reset";
export const USERS_FETCH = "users_fetch";
export const USERS_FETCH_SUCCESS = "users_fetch_success";
export const USERS_FETCH_RESET = "users_fetch_reset";

// Notification
export const HIDE_MESSAGE = "hide_message";
export const ON_SHOW_LOADER = "on_show_loader";
export const ON_HIDE_LOADER = "on_hide_loader";
export const SHOW_INFO_MESSAGE = "show_info_message";
export const SHOW_SUCCESS_MESSAGE = "show_success_message";
export const SHOW_WARNING_MESSAGE = "show_warning_message";
export const SHOW_ERROR_MESSAGE = "show_error_message";

// Dealer
export const DEALER_CREATE = "dealer_create";
export const DEALER_UPDATE = "dealer_update";
export const DEALER_DELETE = "dealer_delete";
export const DEALER_FETCH = "dealer_fetch";
export const DEALER_FETCH_SUCCESS = "dealer_fetch_success";
export const DEALER_FETCH_RESET = "dealer_fetch_reset";
export const DEALERS_FETCH = "dealers_fetch";
export const DEALERS_FETCH_SUCCESS = "dealers_fetch_success";
export const DEALERS_FETCH_RESET = "dealers_fetch_reset";
export const DEALER_PROVINCES_FETCH = "dealer_provinces_fetch";
export const DEALER_PROVINCES_FETCH_SUCCESS = "dealer_provinces_fetch_success";
export const DEALER_PROVINCES_FETCH_EN_SUCCESS = "dealer_provinces_fetch_en_success";
export const DEALER_PROVINCES_FETCH_RESET = "dealer_provinces_fetch_reset";
export const DEALER_PROVINCES_FETCH_EN_RESET = "dealer_provinces_fetch_en_reset";

// Ticket
export const TICKET_BP_UPDATE = "ticket_update";
export const TICKET_FETCH = "ticket_fetch";
export const TICKET_FETCH_SUCCESS = "ticket_fetch_success";
export const TICKET_FETCH_RESET = "ticket_fetch_reset";
export const TICKETS_BP_FETCH = "tickets_bp_fetch";
export const TICKETS_BP_FETCH_SUCCESS = "tickets_bp_fetch_success";
export const TICKETS_BP_FETCH_RESET = "tickets_bp_fetch_reset";
export const TICKETS_BP_REPORT_STATUS_FETCH = "tickets_bp_report_status_fetch";
export const TICKETS_BP_REPORT_STATUS_FETCH_SUCCESS = "tickets_bp_report_status_fetch_success";
export const TICKETS_BP_REPORT_STATUS_FETCH_RESET = "tickets_bp_report_status_fetch_reset";
export const TICKETS_BP_EXPORT_EXCEL = "tickets_export_excel";


// REPORT
export const REPORT_RESPONSE_RATE_FETCH = "report_response_rate_fetch"
export const REPORT_RESPONSE_RATE_FETCH_SUCCESS = "report_response_rate_fetch_success"
export const REPORT_RESPONSE_RATE_FETCH_RESET = "report_response_rate_fetch_reset"
export const REPORT_RESPONSE_RATE_DAILY_FETCH = "report_response_rate_daily_fetch"
export const REPORT_RESPONSE_RATE_DAILY_FETCH_SUCCESS = "report_response_rate_daily_fetch_success"
export const REPORT_RESPONSE_RATE_DAILY_FETCH_RESET = "report_response_rate_daily_fetch_reset"
export const REPORT_SUMMARY_DEALER_FETCH = "report_summary_dealer_fetch"
export const REPORT_SUMMARY_DEALER_FETCH_SUCCESS = "report_summary_dealer_fetch_success"
export const REPORT_SUMMARY_DEALER_FETCH_RESET = "report_summary_dealer_fetch_reset"
export const REPORT_SUMMARY_FETCH = "report_summary_fetch"
export const REPORT_SUMMARY_FETCH_SUCCESS = "report_summary_fetch_success"
export const REPORT_SUMMARY_FETCH_RESET = "report_summary_fetch_reset"
export const REPORT_CUSTOMER_SURVEY_DETAIL_FETCH = "report_customer_survey_detail_fetch"
export const REPORT_CUSTOMER_SURVEY_DETAIL_FETCH_SUCCESS = "report_customer_survey_detail_fetch_success"
export const REPORT_CUSTOMER_SURVEY_DETAIL_FETCH_RESET = "report_customer_survey_detail_fetch_reset"
export const REPORT_CUSTOMER_SURVEY_DETAIL_NOTEPAD_FETCH = "report_customer_survey_detail_notepad_fetch"
export const REPORT_CUSTOMER_SURVEY_DETAIL_NOTEPAD_FETCH_SUCCESS = "report_customer_survey_detail_notepad_fetch_success"
export const REPORT_CUSTOMER_SURVEY_DETAIL_NOTEPAD_FETCH_RESET = "report_customer_survey_detail_notepad_fetch_reset"

// UPLOAD
export const UPLOADS_FETCH = "uploads_fetch";
export const UPLOADS_FETCH_SUCCESS = "uploads_fetch_success";
export const UPLOADS_FETCH_RESET = "uploads_fetch_reset"
