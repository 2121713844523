import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { USER_SIGNIN, USER_SIGNOUT, DEALER_SIGNIN, DEALER_SIGNOUT, USER_PROFILE_FETCH } from "../actions/types";
import { userSignInSuccess, userSignOutSuccess, userProfileFetchSuccess } from "../actions/AuthActions";
import { ticketFetchReset } from "../actions/TicketActions";
import {
	reportResponseRateFetchReset,
	reportSummaryDealerFetchReset,
	reportSummaryFetchReset,
	reportCustomerSurveyDetailFetchReset,
	reportCustomerSurveyDetailNotepadFetchReset
} from "../actions/ReportActions";
import { showErrorMessage, hideLoader } from "../actions/NotifyActions";
import { authorizationHeader, getErrorMessage } from "../util/apiUtil";
import { isDealerPermissionLogin } from "../util/authUtil";
import { intl } from "../lngProvider/IntlGlobalProvider";
import axios from "axios";

const signInWithEmailPasswordRequest = async (email, password) =>
	await axios
		.post(`${process.env.REACT_APP_API_URL}/api/auth/user`, {
			email,
			password
		})
		.then(response => response.data)
		.catch(error => {
			throw error;
		});

const signInWithPassportIdRequest = async (passport_id, password) =>
	await axios
		.post(`${process.env.REACT_APP_API_URL}/api/auth/dealer`, {
			passport_id,
			password
		})
		.then(response => response.data)
		.catch(error => {
			throw error;
		});

const userProfileFetchRequest = async () =>
	await axios
		.get(`${process.env.REACT_APP_API_URL}/api/auth/profile`, {
			headers: authorizationHeader("bearer")
		})
		.then(response => response.data)
		.catch(error => {
			throw error;
		});

const signOutRequest = async () => { };

function* signInUserWithPassportId({ payload: { passport_id, password } }) {
	try {
		const data = yield call(signInWithPassportIdRequest, passport_id, password);
		if (data.success && isDealerPermissionLogin(data.success.user)) {
			const { token, token_type } = data.success;
			sessionStorage.setItem("user", JSON.stringify({ token, token_type }));
			yield put(userSignInSuccess(data.success.user));
			yield put(hideLoader());
		} else {
			throw new Error(intl.formatMessage({ id: "errors.signin.permission" }));
		}
	} catch (error) {
		yield put(showErrorMessage(getErrorMessage(error)));
	}
}

function* signInUserWithEmailPassword({ payload: { email, password } }) {
	try {
		const data = yield call(signInWithEmailPasswordRequest, email, password);
		if (data.success) {
			if (!data.success.user.permission.bp_dashboard) {
				throw new Error(intl.formatMessage({ id: "errors.signin.admin.permission" }));
			} else {
				const { token, token_type } = data.success;
				sessionStorage.setItem("user", JSON.stringify({ token, token_type }));
				yield put(userSignInSuccess(data.success.user));
				yield put(hideLoader());
			}
		}
	} catch (error) {
		yield put(showErrorMessage(getErrorMessage(error)));
	}
}

function* userProfileFetch() {
	try {
		const data = yield call(userProfileFetchRequest);
		yield put(userProfileFetchSuccess(data));
	} catch (error) {
		yield put(showErrorMessage(getErrorMessage(error)));
	}
}

function* signOut() {
	try {
		const data = yield call(signOutRequest);
		sessionStorage.removeItem("user");
		yield put(ticketFetchReset());
		yield put(reportResponseRateFetchReset());
		yield put(reportSummaryDealerFetchReset());
		yield put(reportSummaryFetchReset());
		yield put(reportCustomerSurveyDetailFetchReset());
		yield put(reportCustomerSurveyDetailNotepadFetchReset());
		yield put(userSignOutSuccess(data));
	} catch (error) {
		yield put(showErrorMessage(getErrorMessage(error)));
	}
}

export function* userSignInWatcher() {
	yield takeEvery(USER_SIGNIN, signInUserWithEmailPassword);
}

export function* userProfileFetchWatcher() {
	yield takeEvery(USER_PROFILE_FETCH, userProfileFetch);
}

export function* userSignOutWatcher() {
	yield takeEvery(USER_SIGNOUT, signOut);
}

export function* dealerSignInWatcher() {
	yield takeEvery(DEALER_SIGNIN, signInUserWithPassportId);
}

export function* dealerSignOutWatcher() {
	yield takeEvery(DEALER_SIGNOUT, signOut);
}

export default function* rootSaga() {
	yield all([
		fork(userSignInWatcher),
		fork(userSignOutWatcher),
		fork(userProfileFetchWatcher),
		fork(dealerSignInWatcher),
		fork(dealerSignOutWatcher)
	]);
}
