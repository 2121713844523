import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Header from "../components/navigator/Header";
import Footer from "../components/navigator/Footer";
import Sidebar from "containers/SideNav/index";
import { userProfileFetch } from "../actions/AuthActions";
import { ABOVE_THE_HEADER, BELOW_THE_HEADER, COLLAPSED_DRAWER, FIXED_DRAWER, HORIZONTAL_NAVIGATION } from "../actions/types";
import { isIOS, isMobile, isIE } from "react-device-detect";
import asyncComponent from "../util/asyncComponent";
import TopNav from "../components/navigator/TopNav";

class App extends React.Component {
	componentDidMount() {
		const { authUser, userProfileFetch } = this.props;
		if (!authUser._id) {
			userProfileFetch();
		}
	}

	render() {
		const { match, drawerType, navigationStyle, horizontalNavPosition } = this.props;
		const drawerStyle = drawerType.includes(FIXED_DRAWER)
			? "fixed-drawer"
			: drawerType.includes(COLLAPSED_DRAWER)
				? "collapsible-drawer"
				: "mini-drawer";

		//set default height and overflow for iOS mobile Safari 10+ support.
		if (isIOS && isMobile) {
			document.body.classList.add("ios-mobile-view-height");
		} else if (document.body.classList.contains("ios-mobile-view-height")) {
			document.body.classList.remove("ios-mobile-view-height");
		}
		const appWidth = isIE && document.getElementById("app-main-container") ? document.getElementById("app-main-container").offsetWidth : '100%';

		return (
			<div className={`app-container ${drawerStyle}`}>
				<Sidebar />
				<div className="app-main-container" id="app-main-container">
					<div className={`app-header ${navigationStyle === HORIZONTAL_NAVIGATION ? "app-header-horizontal" : ""}`}>
						{navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === ABOVE_THE_HEADER && (
							<TopNav styleName="app-top-header" />
						)}
						<Header />
						{navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === BELOW_THE_HEADER && <TopNav />}
					</div>

					<div className="app-main-content-wrapper">
						<div className="app-main-content" style={{ minWidth: appWidth }}>
							<Switch>
								<Route path={`${match.url}/main`} component={asyncComponent(() => import("./routes/main"))} />
								<Route path={`${match.url}/report`} component={asyncComponent(() => import("./routes/report"))} />
								<Route path={`${match.url}/dealer`} component={asyncComponent(() => import("./routes/dealer"))} />
								<Route path={`${match.url}/user`} component={asyncComponent(() => import("./routes/user"))} />
								<Route path={`${match.url}/ticket`} component={asyncComponent(() => import("./routes/ticket"))} />

								<Route component={asyncComponent(() => import("../components/error/Error404"))} />
							</Switch>
						</div>
						<Footer />
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = ({ setting, auth: { authUser } }) => {
	const { drawerType, navigationStyle, horizontalNavPosition } = setting;
	return { drawerType, navigationStyle, horizontalNavPosition, authUser };
};
export default withRouter(connect(mapStateToProps, { userProfileFetch })(App));
