import { all } from "redux-saga/effects";
import AuthSagas from "./AuthSagas";
import DealerSagas from "./DealerSagas";
import TicketSagas from "./TicketSagas";
import UserSagas from "./UserSagas";
import ReportSagas from "./ReportSagas";
import UploadSagas from "./UploadSagas";


export default function* rootSaga(getState) {
	yield all([AuthSagas(), DealerSagas(), TicketSagas(), UserSagas(), ReportSagas(), UploadSagas()]);
}