import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { dealerSignIn, showLoader } from "../../../actions";
import { renderFields } from "../../../util/formUtil";
import { signInDealerFormFields } from "../formFields";
import IntlMessages from "../../../util/IntlMessages";
import Button from "@material-ui/core/Button";

class SignInDealerForm extends Component {
	render() {
		const { handleSubmit, formValues } = this.props;
		return (
			<form
				onSubmit={handleSubmit(() => {
					formValues.password = " ";
					this.props.showLoader();
					this.props.dealerSignIn(formValues);
				})}
				noValidate
			>
				<fieldset>
					{renderFields(signInDealerFormFields)}
					<div className="mb-3 d-flex align-items-center justify-content-between">
						<Button type="submit" variant="contained" color="primary">
							<IntlMessages id="signin" />
						</Button>
					</div>
				</fieldset>
			</form>
		);
	}
}

function validate(values) {
	const errors = {};
	signInDealerFormFields.forEach(({ name, required, type }) => {
		if (!values[name] && required) {
			errors[name] = <IntlMessages id="errors.field.required" />;
			return;
		}
	});
	return errors;
}

const mapStateToProps = ({ form }) => {
	const formValues = form.SignInDealerForm ? form.SignInDealerForm.values : null;
	return { formValues };
};

SignInDealerForm = reduxForm({
	validate,
	form: "SignInDealerForm"
})(SignInDealerForm);

export default withRouter(
	connect(
		mapStateToProps,
		{ showLoader, dealerSignIn }
	)(SignInDealerForm)
);
