import React from "react";
import {
	TextField,
	Input,
	InputLabel,
	MenuItem,
	FormControl,
	FormControlLabel,
	FormLabel,
	FormGroup,
	FormHelperText,
	Select,
	Checkbox,
	Switch,
	Radio,
	RadioGroup
} from "@material-ui/core";
import { Autocomplete } from '@material-ui/lab';
import { DatePicker, InlineDatePicker, DateTimePicker } from "material-ui-pickers";
import IntlMessages from "../../util/IntlMessages";
import { injectIntl } from "react-intl";
import _ from "lodash";

export default injectIntl(({ input, type, meta, ...rest }) => {
	const { error, touched } = meta ? meta : {};
	const TextFieldValid = ["text", "password", "number", "email", "textarea"];
	const HiddenFieldValid = ["hidden"];
	const CheckBoxFieldValid = ["checkbox"];
	const SwitchFieldValid = ["switch"];
	const SelectFieldValid = ["dropdown"];
	const AutoCompleteFieldValid = ["autocomplete"];
	const RadioGroupFieldValid = ["radiogroup"];
	const DateFieldValid = ["date"];
	const DateTimeFieldValid = ["datetime"];
	const HeaderFieldValid = ["header"];
	const TitleFieldValid = ["title"];
	const HrFieldValid = ["hr"];
	let autocompleteValue;

	if (AutoCompleteFieldValid.includes(type)) {
		if (rest.default) {
			rest.options.unshift(
				{ display: <IntlMessages id={rest.default && rest.default.display ? rest.default.display : "form.select.default"} />, value: rest.default.value, intl: false }
			);
		}
	}

	const getSelectedOption = () => {
		let current = _.find(rest.options, function (o) { return o.value == input.value; });
		if (current != undefined) {
			const { id, values } = current.display.props;
			return { title: `${rest.intl.formatMessage({ id }, values)}`, value: current.value };
		} else if (rest.default) {
			return { title: `${rest.intl.formatMessage({ id: rest.default.display })}`, value: rest.default.value };
		} 
	};

	return (
		<React.Fragment>
			{HrFieldValid.includes(type) && <hr />}
			{HeaderFieldValid.includes(type) && <h2 {...rest}>{rest.label}</h2>}
			{HiddenFieldValid.includes(type) && <TextField type={type} {...input} />}

			{TitleFieldValid.includes(type) && (
				<FormControl className={rest.className}   >
					<FormLabel>
						{rest.label} {rest.required && "*"}
					</FormLabel>
				</FormControl>
			)}

			{TextFieldValid.includes(type) && (
				<TextField
					type={type}
					fullWidth
					error={touched && error && true}
					helperText={touched && error}
					{...input}
					{...rest}
				/>
			)}

			{CheckBoxFieldValid.includes(type) && (
				<FormControl className={rest.className}>
					<FormControlLabel control={<Checkbox checked={input.value} onChange={input.onChange} />} label={rest.label} />
				</FormControl>
			)}

			{SwitchFieldValid.includes(type) && (
				<FormControl className={rest.className}>
					<FormControlLabel control={<Switch checked={input.value} onChange={input.onChange} color="primary" />} label={rest.label} />
				</FormControl>
			)}

			{SelectFieldValid.includes(type) && (
				<FormControl className={rest.className} disabled={rest.disabled} >
					<InputLabel htmlFor={input.name} error={touched && error && true}>
						{rest.label} {rest.required && "*"}
					</InputLabel>
					<Select fullWidth error={touched && error && true} input={<Input id={input.name} />} {...input}>
						<MenuItem value={rest.default && rest.default.value ? rest.default.value : ""}>
							<IntlMessages id={rest.default && rest.default.display ? rest.default.display : "form.select.default"} />
						</MenuItem>
						{rest.options &&
							rest.options.map(({ display, value, intl = true }) => {
								return (
									<MenuItem key={input.name + "_" + value} value={value}>
										{intl ? <IntlMessages id={display} /> : display}
									</MenuItem>
								);
							})}
					</Select>
					{touched && error && <FormHelperText error>{error}</FormHelperText>}
				</FormControl>
			)}

			{AutoCompleteFieldValid.includes(type) && (
				<FormControl className={rest.className}   >
					<Autocomplete
						value={getSelectedOption()}
						options={rest.options && rest.options.map(({ display: { props: { id, values } }, value, intl = true }) => {
							return { title: `${rest.intl.formatMessage({ id }, values)}`, value }
						})}
						onChange={(event, value) => value && input.onChange(value.value)}
						getOptionLabel={(option) => option.title}
						getOptionSelected={(optons, value) => optons.value === value.value}
						noOptionsText={rest.intl.formatMessage({ id: 'errors.data.notfound' })}
						renderInput={(params) =>
							<TextField
								{...params} type={type} fullWidth error={touched && error && true} helperText={touched && error}
								label={rest.label}
							/>
						}
					/>
				</FormControl>
			)}

			{RadioGroupFieldValid.includes(type) && (
				<FormControl className={rest.className}   >
					<FormLabel>
						{rest.label} {rest.required && "*"}
					</FormLabel>
					<RadioGroup row={rest.row} value={input.value} onChange={input.onChange} >
						{rest.options &&
							rest.options.map(({ display, value, intl = true }) => {
								return (
									<FormControlLabel disabled={rest.disabled} key={input.name + "_" + value} value={value} control={<Radio />} label={intl ? <IntlMessages id={display} /> : display} />
								);
							})
						}
					</RadioGroup>
					{touched && error && <FormHelperText className="mt-0" error>{error}</FormHelperText>}
				</FormControl>
			)}

			{DateFieldValid.includes(type) && (
				<FormControl className={rest.className}>
					<InlineDatePicker
						fullWidth
						keyboard
						clearable
						onlyCalendar
						format="YYYY-MM-DD"
						mask={value => (value ? [/\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, "-", /\d/, /\d/] : [])}
						placeholder="yyyy-mm-dd"
						leftArrowIcon={<i className="zmdi zmdi-arrow-back" />}
						rightArrowIcon={<i className="zmdi zmdi-arrow-forward" />}
						required={rest.required}
						label={rest.label}
						error={touched && error && true}
						helperText={touched && error}
						{...input}
					/>
				</FormControl>
			)}
			{DateTimeFieldValid.includes(type) && (
				<FormControl className={rest.className}>
					<DateTimePicker
						fullWidth
						format="YYYY-MM-DD HH:mm"
						ampm={false}
						required={rest.required}
						label={rest.label}
						error={touched && error && true}
						helperText={touched && error}
						{...input}
					/>
				</FormControl>
			)}
		</React.Fragment>
	);
});
