import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { reducer as reduxForm } from "redux-form";
import AuthReducer from "./AuthReducer";
import NotifyReducer from "./NotifyReducer";
import SettingReducer from "./SettingReducer";
import DealerReducer from "./DealerReducer";
import TicketReducer from "./TicketReducer";
import UserReducer from "./UserReducer";
import ReportReducer from "./ReportReducer";
import UploadReducer from "./UploadReducer";


export default history =>
	combineReducers({
		router: connectRouter(history),
		setting: SettingReducer,
		notify: NotifyReducer,
		auth: AuthReducer,
		dealer: DealerReducer,
		ticket: TicketReducer,
		user: UserReducer,
		report: ReportReducer,
		upload: UploadReducer,
		form: reduxForm
	});
