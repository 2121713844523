import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
	TICKET_FETCH,
	TICKET_BP_UPDATE,
	TICKETS_BP_FETCH,
	TICKETS_BP_REPORT_STATUS_FETCH,
	TICKETS_BP_EXPORT_EXCEL
} from "../actions/types";
import {
	ticketFetchSuccess,
	ticketsBpFetchSuccess,
	ticketsBpReportStatusFetchSuccess
} from "../actions/TicketActions";
import { showErrorMessage, showSuccessMessage, hideLoader } from "../actions/NotifyActions";
import { authorizationHeader, authorizationHeaderWithMultipartFormData, getErrorMessage } from "../util/apiUtil";
import { intl } from "../lngProvider/IntlGlobalProvider";
import axios from "axios";
import FileSaver from "file-saver";
import moment from "moment";
import _ from "lodash";
import { objectToFormData } from 'object-to-formdata';

// API
const ticketFetchRequest = async id =>
	await axios
		.get(`${process.env.REACT_APP_API_URL}/api/tickets/${id}`, {
			headers: authorizationHeader("bearer")
		})
		.then(response => response.data)
		.catch(error => {
			throw error;
		});

const ticketsFetchRequest = async payload => {
	let url = `${process.env.REACT_APP_API_URL}/api/tickets`;
	let params = { ...payload };
	params = _.omitBy(params, _.isEmpty);
	params.sort = "created_oa_date";
	params.order = "desc";

	return await axios
		.get(url, {
			headers: authorizationHeader("bearer"),
			params: params
		})
		.then(response => response.data)
		.catch(error => {
			throw error;
		});
};

const ticketsReportStatusFetchRequest = async payload => {
	let url = `${process.env.REACT_APP_API_URL}/api/tickets/report/status`;
	let params = { ...payload };
	params = _.omitBy(params, _.isEmpty);

	return await axios
		.get(url, {
			headers: authorizationHeader("bearer"),
			params: params
		})
		.then(response => response.data)
		.catch(error => {
			throw error;
		});
};

const ticketBpUpdateRequest = async values => {
	const { imageFiles, pdfFiles, ...rest } = values;
	let formData = objectToFormData(rest);
	imageFiles.forEach(file => {
		formData.append('imageFiles[]', file);
	})
	pdfFiles.forEach(file => {
		formData.append('pdfFiles[]', file);
	})

	return await axios
		.put(`${process.env.REACT_APP_API_URL}/api/ticketbps/${values._id}`, formData, {
			headers: authorizationHeaderWithMultipartFormData("bearer")
		})
		.then(response => response.data)
		.catch(error => {
			throw error;
		});
}

const ticketsBpExportExcelRequest = async payload => {
	let url = `${process.env.REACT_APP_API_URL}/api/ticketbps/report/excel`;
	let params = { ...payload };
	params = _.omitBy(params, _.isEmpty);

	return await axios
		.get(url, {
			headers: authorizationHeader("bearer"),
			params: params,
			responseType: "blob"
		})
		.then(response => {
			return response.data;
		})
		.catch(error => {
			throw error;
		});
};

// WORKER
function* ticketFetch({ payload }) {
	try {
		const data = yield call(ticketFetchRequest, payload);
		yield put(ticketFetchSuccess(data));
	} catch (error) {
		yield put(showErrorMessage(getErrorMessage(error)));
	}
}

function* ticketBpUpdate({ payload }) {
	try {
		const data = yield call(ticketBpUpdateRequest, payload);
		if (data.success) {
			yield put(ticketFetchSuccess(data.success.result));
			if (data.success.result.status == "B") {
				yield put(showSuccessMessage(intl.formatMessage({ id: "success.updated.ticket.status.B" })));
			} else {
				yield put(showSuccessMessage(intl.formatMessage({ id: "success.updated" })));
			}
		}
	} catch (error) {
		yield put(showErrorMessage(getErrorMessage(error)));
	}
}


function* ticketsBpFetch({ payload }) {
	try {
		payload.from_created_oa_date =
			payload.from_created_oa_date instanceof Object
				? payload.from_created_oa_date.format("YYYY-MM-DD")
				: payload.from_created_oa_date;
		payload.to_created_oa_date =
			payload.to_created_oa_date instanceof Object ? payload.to_created_oa_date.format("YYYY-MM-DD") : payload.to_created_oa_date;

		const data = yield call(ticketsFetchRequest, payload);
		yield put(ticketsBpFetchSuccess(data));
		yield put(hideLoader());
	} catch (error) {
		yield put(showErrorMessage(getErrorMessage(error)));
	}
}

function* ticketsBpReportStatusFetch({ payload }) {
	try {
		payload.from_created_oa_date =
			payload.from_created_oa_date instanceof Object
				? payload.from_created_oa_date.format("YYYY-MM-DD")
				: payload.from_created_oa_date;
		payload.to_created_oa_date =
			payload.to_created_oa_date instanceof Object ? payload.to_created_oa_date.format("YYYY-MM-DD") : payload.to_created_oa_date;

		const data = yield call(ticketsReportStatusFetchRequest, payload);
		yield put(ticketsBpReportStatusFetchSuccess(data));
		yield put(hideLoader());
	} catch (error) {
		yield put(showErrorMessage(getErrorMessage(error)));
	}
}

function* ticketsBpExportExcel({ payload }) {
	console.log('test')
	try {
		payload.from_created_oa_date = payload.from_created_oa_date instanceof Object ? payload.from_created_oa_date.format("YYYY-MM-DD") : payload.from_created_oa_date;
		payload.to_created_oa_date = payload.to_created_oa_date instanceof Object ? payload.to_created_oa_date.format("YYYY-MM-DD") : payload.to_created_oa_date;

		const data = yield call(ticketsBpExportExcelRequest, payload);
		FileSaver.saveAs(data, `tickets_bp_survey_${moment().format("DDMMYYYY")}.xlsx`);
		yield put(hideLoader());
	} catch (error) {
		yield put(showErrorMessage(getErrorMessage(error)));
	}
}

// WATCHER
function* ticketFetchWatcher() {
	yield takeEvery(TICKET_FETCH, ticketFetch);
}

// --------------------------------------------------------------------------------------------
function* ticketBpUpdateWatcher() {
	yield takeEvery(TICKET_BP_UPDATE, ticketBpUpdate);
}

function* ticketsBpFetchWatcher() {
	yield takeEvery(TICKETS_BP_FETCH, ticketsBpFetch);
}

function* ticketsBpReportStatusFetchWatcher() {
	yield takeEvery(TICKETS_BP_REPORT_STATUS_FETCH, ticketsBpReportStatusFetch);
}

function* ticketsBpExportExcelWatcher() {
	yield takeEvery(TICKETS_BP_EXPORT_EXCEL, ticketsBpExportExcel);
}

export default function* rootSaga() {
	yield all([
		fork(ticketFetchWatcher),
		fork(ticketBpUpdateWatcher),
		fork(ticketsBpFetchWatcher),
		fork(ticketsBpReportStatusFetchWatcher),
		fork(ticketsBpExportExcelWatcher),
	]);
}
