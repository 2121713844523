import {
	DEALER_TYPES,
	PERMISSION_LOGIN,
	PERMISSION_GENERAL_REPORT,
	PERMISSION_MANAGEMENT_REPORT,
	PERMISSION_TICKET_EDIT,
	PERMISSION_TICKET_MANAGEMENT_EDIT,
	PERMISSION_MULTI_BRANCHES_EDIT,	
	PERMISSION_TICKET_BP_VIEW
} from "../constants/Systems";


export const isDealerPermissionLogin = authUser => {
	let result = false;
	if (authUser && authUser.type && authUser.position_title && DEALER_TYPES.includes(authUser.type) && authUser.dealer && authUser.dealer.bp == "Y") {
		PERMISSION_LOGIN.forEach(element => {
			if (authUser.position_title.indexOf(element) > -1) {
				result = true;
			}
		});
	}
	return result;
};

export const isDealerPermissionGeneralReport = authUser => {
	let result = false;
	if (authUser && authUser.type && authUser.position_title && DEALER_TYPES.includes(authUser.type)) {
		PERMISSION_GENERAL_REPORT.forEach(element => {
			if (authUser.position_title.indexOf(element) > -1) {
				result = true;
			}
		});
	}
	return result;
};

export const isDealerPermissionManagementReport = authUser => {
	let result = false;
	if (authUser && authUser.type && authUser.position_title && DEALER_TYPES.includes(authUser.type)) {
		PERMISSION_MANAGEMENT_REPORT.forEach(element => {
			if (authUser.position_title.indexOf(element) > -1) {
				result = true;
			}
		});
	}
	return result;
};

export const isDealerPermissionTicketBpView = authUser => {
	let result = false;
	if (authUser && authUser.type && authUser.position_title && DEALER_TYPES.includes(authUser.type)) {
		PERMISSION_TICKET_BP_VIEW.forEach(element => {
			if (authUser.position_title.indexOf(element) > -1) {
				result = true;
			}
		});
	}
	return result;
};

export const isDealerPermissionTicketEdit = authUser => {
	let result = false;
	if (authUser && authUser.type && authUser.position_title && DEALER_TYPES.includes(authUser.type)) {
		PERMISSION_TICKET_EDIT.forEach(element => {
			if (authUser.position_title.indexOf(element) > -1) {
				result = true;
			}
		});
	}
	return result;
};

export const isDealerPermissionTicketManagementEdit = authUser => {
	let result = false;
	if (authUser && authUser.type && authUser.position_title && DEALER_TYPES.includes(authUser.type)) {
		PERMISSION_TICKET_MANAGEMENT_EDIT.forEach(element => {
			if (authUser.position_title.indexOf(element) > -1) {
				result = true;
			}
		});
	}
	return result;
};

export const isDealerPermissionMultiBranchesEdit = authUser => {
	let result = false;
	if (authUser && authUser.type && authUser.position_title && DEALER_TYPES.includes(authUser.type)) {
		PERMISSION_MULTI_BRANCHES_EDIT.forEach(element => {
			if (authUser.position_title.indexOf(element) > -1) {
				result = true;
			}
		});
	}
	return result;
};
