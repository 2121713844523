import {
	DEALER_FETCH_SUCCESS,
	DEALERS_FETCH_SUCCESS,
	DEALER_PROVINCES_FETCH_SUCCESS,
	DEALER_PROVINCES_FETCH_EN_SUCCESS,
	DEALER_FETCH_RESET,
	DEALERS_FETCH_RESET,
	DEALER_PROVINCES_FETCH_RESET,
	DEALER_PROVINCES_FETCH_EN_RESET
} from "../actions/types";

const INIT_STATE = {
	dealers: [],
	dealer: {},
	dealer_provinces: [],
	dealer_provinces_en: []
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case DEALERS_FETCH_SUCCESS: {
			return {
				...state,
				dealer: {},
				dealers: action.payload
			};
		}
		case DEALER_FETCH_SUCCESS: {
			return {
				...state,
				dealer: action.payload
			};
		}
		case DEALER_PROVINCES_FETCH_SUCCESS: {
			return {
				...state,
				dealer_provinces: action.payload
			};
		}
		case DEALER_PROVINCES_FETCH_EN_SUCCESS: {
			return {
				...state,
				dealer_provinces_en: action.payload
			};
		}
		case DEALERS_FETCH_RESET: {
			return {
				...state,
				dealers: []
			};
		}
		case DEALER_FETCH_RESET: {
			return {
				...state,
				dealer: {}
			};
		}
		case DEALER_PROVINCES_FETCH_RESET: {
			return {
				...state,
				dealer_provinces: []
			};
		}
		case DEALER_PROVINCES_FETCH_EN_RESET: {
			return {
				...state,
				dealer_provinces_en: []
			};
		}
		default:
			return state;
	}
};
