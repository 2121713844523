export const signInAdminFormFields = [
	{ label: "user.email", name: "email", type: "email", required: true, margin: "normal", className: "mt-1 my-sm-3" },
	{ label: "user.password", name: "password", type: "password", required: true, margin: "normal", className: "mt-1 my-sm-3" }
];

export const signInDealerFormFields = [
	{ label: "dealer.tds.passport_id", name: "passport_id", type: "text", required: true, margin: "normal", className: "mt-1 my-sm-3" },
	{ label: "dealer.tds.password", name: "password", type: "hidden", value: "innovation", required: false, margin: "normal", className: "mt-1 my-sm-3" }
];

export const reportResponseRateSearchFormFields = [
	{
		label: "quarter", name: "quarter", type: "dropdown", required: true, margin: "normal", className: "col-6 col-lg-_3col-md-3 col-sm-3 pr-2 mt-0 mb-2",
		default: { display: "quarter.all", value: "all" },
		options: [
			{ display: "quarter.1", value: "2" },
			{ display: "quarter.2", value: "3" },
			{ display: "quarter.3", value: "4" },
			{ display: "quarter.4", value: "1" },
		]
	},
	{
		label: "year", name: "year", type: "dropdown", required: true, margin: "normal", className: "col-6 col-lg-3 col-md-3 col-sm-3 pr-2 mt-0 mb-2",
		options: [
			{ display: "year.2024", value: "2024" },
			{ display: "year.2023", value: "2023" },
		]
	},
	{
		label: "dealer.dealer_service_region", name: "dealer.dealer_service_region", type: "dropdown", required: true, margin: "normal", className: "col-6 col-lg-3 col-md-6 col-sm-6 pr-2 mt-0 mb-2",
		default: { display: "dealer.dealer_service_region.all", value: "all" },
		options: [
			{ display: "dealer.dealer_service_region.Region1", value: "REGION 1" },
			{ display: "dealer.dealer_service_region.Region2", value: "REGION 2" },
			{ display: "dealer.dealer_service_region.Region3", value: "REGION 3" },
			{ display: "dealer.dealer_service_region.Region4", value: "REGION 4" },
			{ display: "dealer.dealer_service_region.Region5", value: "REGION 5" }
		]
	},
	{
		label: "dealer.service_area_code", name: "dealer.service_area_code", type: "dropdown", required: true, margin: "normal", className: "col-6 col-lg-3 col-md-6 col-sm-6 pr-2 mt-0 mb-2",
		default: { display: "dealer.service_area_code.all", value: "all" },
		options: [
			{ display: "dealer.service_area_code.R1-1", value: "R1-1" },
			{ display: "dealer.service_area_code.R1-2", value: "R1-2" },
			{ display: "dealer.service_area_code.R1-3", value: "R1-3" },
			{ display: "dealer.service_area_code.R1-4", value: "R1-4" },
			{ display: "dealer.service_area_code.R2-1", value: "R2-1" },
			{ display: "dealer.service_area_code.R2-2", value: "R2-2" },
			{ display: "dealer.service_area_code.R2-3", value: "R2-3" },
			{ display: "dealer.service_area_code.R2-4", value: "R2-4" },
			{ display: "dealer.service_area_code.R3-1", value: "R3-1" },
			{ display: "dealer.service_area_code.R3-2", value: "R3-2" },
			{ display: "dealer.service_area_code.R3-3", value: "R3-3" },
			{ display: "dealer.service_area_code.R4-1", value: "R4-1" },
			{ display: "dealer.service_area_code.R4-2", value: "R4-2" },
			{ display: "dealer.service_area_code.R4-3", value: "R4-3" },
			{ display: "dealer.service_area_code.R4-4", value: "R4-4" },
			{ display: "dealer.service_area_code.R5-1", value: "R5-1" },
			{ display: "dealer.service_area_code.R5-2", value: "R5-2" },
			{ display: "dealer.service_area_code.R5-3", value: "R5-3" },
		]
	},
	{
		label: "dealer.dealer_region", name: "dealer.dealer_region", type: "dropdown", required: true, margin: "normal", className: "col-6 col-lg-3 col-md-6 col-sm-6 pr-2 mt-0 mb-2",
		default: { display: "dealer.dealer_region.all", value: "all" },
		options: [
			{ display: "dealer.dealer_region.G.BANGKOK", value: "G.BANGKOK" },
			{ display: "dealer.dealer_region.CENTRAL", value: "CENTRAL" },
			{ display: "dealer.dealer_region.EAST", value: "EAST" },
			{ display: "dealer.dealer_region.NORTH", value: "NORTH" },
			{ display: "dealer.dealer_region.NORTH-EAST", value: "NORTH-EAST" },
			{ display: "dealer.dealer_region.SOUTH", value: "SOUTH" },
		]
	},
	{
		label: "dealer.dealer_name", name: "dealer.dealer_id", type: "autocomplete", required: false, margin: "normal", className: "col-6 col-lg-9 col-md-6 col-sm-6 pr-2 mt-0 mb-2",
		default: { display: "dealer.dealer_name.all", value: "all" },
		options: []
	}
]

export const reportResponseRateDailySearchFormFields = [
	{ label: "report.from", name: "from", type: "date", required: true, margin: "normal", className: "col-6 col-lg-3 col-md-3 col-sm-3 pr-2 mt-0 mb-2" },
	{ label: "report.to", name: "to", type: "date", required: true, margin: "normal", className: "col-6 col-lg-3 col-md-3 col-sm-3 pr-2 mt-0 mb-2" },
	{
		label: "dealer.dealer_service_region", name: "dealer.dealer_service_region", type: "dropdown", required: true, margin: "normal", className: "col-6 col-lg-3 col-md-6 col-sm-6 pr-2 mt-0 mb-2",
		default: { display: "dealer.dealer_service_region.all", value: "all" },
		options: [
			{ display: "dealer.dealer_service_region.Region1", value: "REGION 1" },
			{ display: "dealer.dealer_service_region.Region2", value: "REGION 2" },
			{ display: "dealer.dealer_service_region.Region3", value: "REGION 3" },
			{ display: "dealer.dealer_service_region.Region4", value: "REGION 4" },
			{ display: "dealer.dealer_service_region.Region5", value: "REGION 5" }
		]
	},
	{
		label: "dealer.service_area_code", name: "dealer.service_area_code", type: "dropdown", required: true, margin: "normal", className: "col-6 col-lg-3 col-md-6 col-sm-6 pr-2 mt-0 mb-2",
		default: { display: "dealer.service_area_code.all", value: "all" },
		options: [
			{ display: "dealer.service_area_code.R1-1", value: "R1-1" },
			{ display: "dealer.service_area_code.R1-2", value: "R1-2" },
			{ display: "dealer.service_area_code.R1-3", value: "R1-3" },
			{ display: "dealer.service_area_code.R1-4", value: "R1-4" },
			{ display: "dealer.service_area_code.R2-1", value: "R2-1" },
			{ display: "dealer.service_area_code.R2-2", value: "R2-2" },
			{ display: "dealer.service_area_code.R2-3", value: "R2-3" },
			{ display: "dealer.service_area_code.R2-4", value: "R2-4" },
			{ display: "dealer.service_area_code.R3-1", value: "R3-1" },
			{ display: "dealer.service_area_code.R3-2", value: "R3-2" },
			{ display: "dealer.service_area_code.R3-3", value: "R3-3" },
			{ display: "dealer.service_area_code.R4-1", value: "R4-1" },
			{ display: "dealer.service_area_code.R4-2", value: "R4-2" },
			{ display: "dealer.service_area_code.R4-3", value: "R4-3" },
			{ display: "dealer.service_area_code.R4-4", value: "R4-4" },
			{ display: "dealer.service_area_code.R5-1", value: "R5-1" },
			{ display: "dealer.service_area_code.R5-2", value: "R5-2" },
			{ display: "dealer.service_area_code.R5-3", value: "R5-3" },
		]
	},
	{
		label: "dealer.dealer_region", name: "dealer.dealer_region", type: "dropdown", required: true, margin: "normal", className: "col-6 col-lg-3 col-md-6 col-sm-6 pr-2 mt-0 mb-2",
		default: { display: "dealer.dealer_region.all", value: "all" },
		options: [
			{ display: "dealer.dealer_region.G.BANGKOK", value: "G.BANGKOK" },
			{ display: "dealer.dealer_region.CENTRAL", value: "CENTRAL" },
			{ display: "dealer.dealer_region.EAST", value: "EAST" },
			{ display: "dealer.dealer_region.NORTH", value: "NORTH" },
			{ display: "dealer.dealer_region.NORTH-EAST", value: "NORTH-EAST" },
			{ display: "dealer.dealer_region.SOUTH", value: "SOUTH" },
		]
	},
	{
		label: "dealer.dealer_name", name: "dealer.dealer_id", type: "autocomplete", required: false, margin: "normal", className: "col-6 col-lg-9 col-md-6 col-sm-6 pr-2 mt-0 mb-2",
		default: { display: "dealer.dealer_name.all", value: "all" },
		options: []
	}
]

export const reportResponseRateByDealerSearchFormFields = [
	{
		label: "quarter", name: "quarter", type: "dropdown", required: true, margin: "normal", className: "col-6 col-lg-_3 col-md-3 col-sm-3 pr-2 mt-0 mb-2",
		default: { display: "quarter.all", value: "all" },
		options: [
			{ display: "quarter.1", value: "2" },
			{ display: "quarter.2", value: "3" },
			{ display: "quarter.3", value: "4" },
			{ display: "quarter.4", value: "1" },
		]
	},
	{
		label: "year", name: "year", type: "dropdown", required: true, margin: "normal", className: "col-6 col-lg-3 col-md-3 col-sm-3 pr-2 mt-0 mb-2",
		options: [
			{ display: "year.2024", value: "2024" },
			{ display: "year.2023", value: "2023" },
		]
	},
	{
		label: "dealer.dealer_name", name: "dealer.dealer_id", type: "autocomplete", required: false, margin: "normal", className: "col-12 col-lg-6 col-md-6 col-sm-6 pr-2 mt-0 mb-2",
		default: { display: "dealer.dealer_name.all", value: "all" },
		options: []
	}
]

export const reportResponseRateDailyByDealerSearchFormFields = [
	{ label: "report.from", name: "from", type: "date", required: true, margin: "normal", className: "col-6 col-lg-3 col-md-3 col-sm-3 pr-2 mt-0 mb-2" },
	{ label: "report.to", name: "to", type: "date", required: true, margin: "normal", className: "col-6 col-lg-3 col-md-3 col-sm-3 pr-2 mt-0 mb-2" },
	{
		label: "dealer.dealer_name", name: "dealer.dealer_id", type: "autocomplete", required: false, margin: "normal", className: "col-12 col-lg-6 col-md-6 col-sm-6 pr-2 mt-0 mb-2",
		default: { display: "dealer.dealer_name.all", value: "all" },
		options: []
	}
]

export const reportSummarySearchFormFields = [
	{
		label: "year", name: "year", type: "dropdown", required: true, margin: "normal", className: "col-12 pr-2 mt-0 mb-2",
		options: [
			{ display: "year.2024", value: "2024" },
			{ display: "year.2023", value: "2023" },
		]
	},
]

export const reportSummaryDealerSearchFormFields = [
	{
		label: "quarter", name: "quarter", type: "dropdown", required: true, margin: "normal", className: "col-6 col-lg-_3col-md-3 col-sm-3 pr-2 mt-0 mb-2",
		default: { display: "quarter.all", value: "all" },
		options: [
			{ display: "quarter.1", value: "2" },
			{ display: "quarter.2", value: "3" },
			{ display: "quarter.3", value: "4" },
			{ display: "quarter.4", value: "1" },
		]
	},
	{
		label: "year", name: "year", type: "dropdown", required: true, margin: "normal", className: "col-6 col-lg-3 col-md-3 col-sm-3 pr-2 mt-0 mb-2",
		options: [
			{ display: "year.2024", value: "2024" },
			{ display: "year.2023", value: "2023" },
		]
	},
	{
		label: "dealer.dealer_service_region", name: "dealer.dealer_service_region", type: "dropdown", required: true, margin: "normal", className: "col-6 col-lg-3 col-md-6 col-sm-6 pr-2 mt-0 mb-2",
		default: { display: "dealer.dealer_service_region.all", value: "all" },
		options: [
			{ display: "dealer.dealer_service_region.Region1", value: "REGION 1" },
			{ display: "dealer.dealer_service_region.Region2", value: "REGION 2" },
			{ display: "dealer.dealer_service_region.Region3", value: "REGION 3" },
			{ display: "dealer.dealer_service_region.Region4", value: "REGION 4" },
			{ display: "dealer.dealer_service_region.Region5", value: "REGION 5" }
		]
	},
	{
		label: "dealer.service_area_code", name: "dealer.service_area_code", type: "dropdown", required: true, margin: "normal", className: "col-6 col-lg-3 col-md-6 col-sm-6 pr-2 mt-0 mb-2",
		default: { display: "dealer.service_area_code.all", value: "all" },
		options: [
			{ display: "dealer.service_area_code.R1-1", value: "R1-1" },
			{ display: "dealer.service_area_code.R1-2", value: "R1-2" },
			{ display: "dealer.service_area_code.R1-3", value: "R1-3" },
			{ display: "dealer.service_area_code.R1-4", value: "R1-4" },
			{ display: "dealer.service_area_code.R2-1", value: "R2-1" },
			{ display: "dealer.service_area_code.R2-2", value: "R2-2" },
			{ display: "dealer.service_area_code.R2-3", value: "R2-3" },
			{ display: "dealer.service_area_code.R2-4", value: "R2-4" },
			{ display: "dealer.service_area_code.R3-1", value: "R3-1" },
			{ display: "dealer.service_area_code.R3-2", value: "R3-2" },
			{ display: "dealer.service_area_code.R3-3", value: "R3-3" },
			{ display: "dealer.service_area_code.R4-1", value: "R4-1" },
			{ display: "dealer.service_area_code.R4-2", value: "R4-2" },
			{ display: "dealer.service_area_code.R4-3", value: "R4-3" },
			{ display: "dealer.service_area_code.R4-4", value: "R4-4" },
			{ display: "dealer.service_area_code.R5-1", value: "R5-1" },
			{ display: "dealer.service_area_code.R5-2", value: "R5-2" },
			{ display: "dealer.service_area_code.R5-3", value: "R5-3" },
		]
	},
	{
		label: "dealer.dealer_region", name: "dealer.dealer_region", type: "dropdown", required: true, margin: "normal", className: "col-6 col-lg-3 col-md-6 col-sm-6 pr-2 mt-0 mb-2",
		default: { display: "dealer.dealer_region.all", value: "all" },
		options: [
			{ display: "dealer.dealer_region.G.BANGKOK", value: "G.BANGKOK" },
			{ display: "dealer.dealer_region.CENTRAL", value: "CENTRAL" },
			{ display: "dealer.dealer_region.EAST", value: "EAST" },
			{ display: "dealer.dealer_region.NORTH", value: "NORTH" },
			{ display: "dealer.dealer_region.NORTH-EAST", value: "NORTH-EAST" },
			{ display: "dealer.dealer_region.SOUTH", value: "SOUTH" },
		]
	},
	{
		label: "dealer.dealer_name", name: "dealer.dealer_id", type: "autocomplete", required: false, margin: "normal", className: "col-6 col-lg-9 col-md-6 col-sm-6 pr-2 mt-0 mb-2",
		default: { display: "dealer.dealer_name.all", value: "all" },
		options: []
	}
]

export const reportSummaryDealerByDealerSearchFormFields = [
	{
		label: "quarter", name: "quarter", type: "dropdown", required: true, margin: "normal", className: "col-6 col-lg-_3col-md-3 col-sm-3 pr-2 mt-0 mb-2",
		default: { display: "quarter.all", value: "all" },
		options: [
			{ display: "quarter.1", value: "2" },
			{ display: "quarter.2", value: "3" },
			{ display: "quarter.3", value: "4" },
			{ display: "quarter.4", value: "1" },
		]
	},
	{
		label: "year", name: "year", type: "dropdown", required: true, margin: "normal", className: "col-6 col-lg-3 col-md-3 col-sm-3 pr-2 mt-0 mb-2",
		options: [
			{ display: "year.2024", value: "2024" },
			{ display: "year.2023", value: "2023" },
		]
	},
	{
		label: "dealer.dealer_name", name: "dealer.dealer_id", type: "autocomplete", required: false, margin: "normal", className: "col-12 col-lg-6 col-md-6 col-sm-6 pr-2 mt-0 mb-2",
		default: { display: "dealer.dealer_name.all", value: "all" },
		options: []
	}
]

export const reportCustomerSurveyDetailSearchFormFields = [
	{
		label: "quarter", name: "quarter", type: "dropdown", required: true, margin: "normal", className: "col-6 col-lg-_3col-md-3 col-sm-3 pr-2 mt-0 mb-2",
		default: { display: "quarter.all", value: "all" },
		options: [
			{ display: "quarter.1", value: "2" },
			{ display: "quarter.2", value: "3" },
			{ display: "quarter.3", value: "4" },
			{ display: "quarter.4", value: "1" },
		]
	},
	{
		label: "year", name: "year", type: "dropdown", required: true, margin: "normal", className: "col-6 col-lg-3 col-md-3 col-sm-3 pr-2 mt-0 mb-2",
		options: [
			{ display: "year.2024", value: "2024" },
			{ display: "year.2023", value: "2023" },
		]
	},
	{
		label: "dealer.dealer_service_region", name: "dealer.dealer_service_region", type: "dropdown", required: true, margin: "normal", className: "col-6 col-lg-3 col-md-6 col-sm-6 pr-2 mt-0 mb-2",
		default: { display: "dealer.dealer_service_region.all", value: "all" },
		options: [
			{ display: "dealer.dealer_service_region.Region1", value: "REGION 1" },
			{ display: "dealer.dealer_service_region.Region2", value: "REGION 2" },
			{ display: "dealer.dealer_service_region.Region3", value: "REGION 3" },
			{ display: "dealer.dealer_service_region.Region4", value: "REGION 4" },
			{ display: "dealer.dealer_service_region.Region5", value: "REGION 5" }
		]
	},
	{
		label: "dealer.service_area_code", name: "dealer.service_area_code", type: "dropdown", required: true, margin: "normal", className: "col-6 col-lg-3 col-md-6 col-sm-6 pr-2 mt-0 mb-2",
		default: { display: "dealer.service_area_code.all", value: "all" },
		options: [
			{ display: "dealer.service_area_code.R1-1", value: "R1-1" },
			{ display: "dealer.service_area_code.R1-2", value: "R1-2" },
			{ display: "dealer.service_area_code.R1-3", value: "R1-3" },
			{ display: "dealer.service_area_code.R1-4", value: "R1-4" },
			{ display: "dealer.service_area_code.R2-1", value: "R2-1" },
			{ display: "dealer.service_area_code.R2-2", value: "R2-2" },
			{ display: "dealer.service_area_code.R2-3", value: "R2-3" },
			{ display: "dealer.service_area_code.R2-4", value: "R2-4" },
			{ display: "dealer.service_area_code.R3-1", value: "R3-1" },
			{ display: "dealer.service_area_code.R3-2", value: "R3-2" },
			{ display: "dealer.service_area_code.R3-3", value: "R3-3" },
			{ display: "dealer.service_area_code.R4-1", value: "R4-1" },
			{ display: "dealer.service_area_code.R4-2", value: "R4-2" },
			{ display: "dealer.service_area_code.R4-3", value: "R4-3" },
			{ display: "dealer.service_area_code.R4-4", value: "R4-4" },
			{ display: "dealer.service_area_code.R5-1", value: "R5-1" },
			{ display: "dealer.service_area_code.R5-2", value: "R5-2" },
			{ display: "dealer.service_area_code.R5-3", value: "R5-3" },
		]
	},
	{
		label: "dealer.dealer_region", name: "dealer.dealer_region", type: "dropdown", required: true, margin: "normal", className: "col-6 col-lg-3 col-md-6 col-sm-6 pr-2 mt-0 mb-2",
		default: { display: "dealer.dealer_region.all", value: "all" },
		options: [
			{ display: "dealer.dealer_region.G.BANGKOK", value: "G.BANGKOK" },
			{ display: "dealer.dealer_region.CENTRAL", value: "CENTRAL" },
			{ display: "dealer.dealer_region.EAST", value: "EAST" },
			{ display: "dealer.dealer_region.NORTH", value: "NORTH" },
			{ display: "dealer.dealer_region.NORTH-EAST", value: "NORTH-EAST" },
			{ display: "dealer.dealer_region.SOUTH", value: "SOUTH" },
		]
	},
	{
		label: "dealer.dealer_name", name: "dealer.dealer_id", type: "autocomplete", required: false, margin: "normal", className: "col-6 col-lg-9 col-md-6 col-sm-6 pr-2 mt-0 mb-2",
		default: { display: "dealer.dealer_name.all", value: "all" },
		options: []
	}
]


export const reportCustomerSurveyDetailByDealerSearchFormFields = [
	{
		label: "quarter", name: "quarter", type: "dropdown", required: true, margin: "normal", className: "col-6 col-lg-_3col-md-3 col-sm-3 pr-2 mt-0 mb-2",
		default: { display: "quarter.all", value: "all" },
		options: [
			{ display: "quarter.1", value: "2" },
			{ display: "quarter.2", value: "3" },
			{ display: "quarter.3", value: "4" },
			{ display: "quarter.4", value: "1" },
		]
	},
	{
		label: "year", name: "year", type: "dropdown", required: true, margin: "normal", className: "col-6 col-lg-3 col-md-3 col-sm-3 pr-2 mt-0 mb-2",
		options: [
			{ display: "year.2024", value: "2024" },
			{ display: "year.2023", value: "2023" },
		]
	},
	{
		label: "dealer.dealer_name", name: "dealer.dealer_id", type: "autocomplete", required: false, margin: "normal", className: "col-12 col-lg-6 col-md-6 col-sm-6 pr-2 mt-0 mb-2",
		default: { display: "dealer.dealer_name.all", value: "all" },
		options: []
	}
]


export const ticketBpSearchFormFields = [
	{
		label: "quarter", name: "quarter", type: "dropdown", required: true, margin: "normal", className: "col-6 col-lg-_3col-md-3 col-sm-3 pr-2 mt-0 mb-2",
		default: { display: "quarter.all", value: "all" },
		options: [
			{ display: "quarter.1", value: "2" },
			{ display: "quarter.2", value: "3" },
			{ display: "quarter.3", value: "4" },
			{ display: "quarter.4", value: "1" },
		]
	},
	{
		label: "year", name: "year", type: "dropdown", required: true, margin: "normal", className: "col-6 col-lg-3 col-md-3 col-sm-3 pr-2 mt-0 mb-2",
		options: [
			{ display: "year.2024", value: "2024" },
			{ display: "year.2023", value: "2023" },
		]
	},
	{
		label: "dealer.dealer_service_region", name: "dealer.dealer_service_region", type: "dropdown", required: true, margin: "normal", className: "col-6 col-lg-3 col-md-6 col-sm-6 pr-2 mt-0 mb-2",
		default: { display: "dealer.dealer_service_region.all", value: "all" },
		options: [
			{ display: "dealer.dealer_service_region.Region1", value: "REGION 1" },
			{ display: "dealer.dealer_service_region.Region2", value: "REGION 2" },
			{ display: "dealer.dealer_service_region.Region3", value: "REGION 3" },
			{ display: "dealer.dealer_service_region.Region4", value: "REGION 4" },
			{ display: "dealer.dealer_service_region.Region5", value: "REGION 5" }
		]
	},
	{
		label: "dealer.service_area_code", name: "dealer.service_area_code", type: "dropdown", required: true, margin: "normal", className: "col-6 col-lg-3 col-md-6 col-sm-6 pr-2 mt-0 mb-2",
		default: { display: "dealer.service_area_code.all", value: "all" },
		options: [
			{ display: "dealer.service_area_code.R1-1", value: "R1-1" },
			{ display: "dealer.service_area_code.R1-2", value: "R1-2" },
			{ display: "dealer.service_area_code.R1-3", value: "R1-3" },
			{ display: "dealer.service_area_code.R1-4", value: "R1-4" },
			{ display: "dealer.service_area_code.R2-1", value: "R2-1" },
			{ display: "dealer.service_area_code.R2-2", value: "R2-2" },
			{ display: "dealer.service_area_code.R2-3", value: "R2-3" },
			{ display: "dealer.service_area_code.R2-4", value: "R2-4" },
			{ display: "dealer.service_area_code.R3-1", value: "R3-1" },
			{ display: "dealer.service_area_code.R3-2", value: "R3-2" },
			{ display: "dealer.service_area_code.R3-3", value: "R3-3" },
			{ display: "dealer.service_area_code.R4-1", value: "R4-1" },
			{ display: "dealer.service_area_code.R4-2", value: "R4-2" },
			{ display: "dealer.service_area_code.R4-3", value: "R4-3" },
			{ display: "dealer.service_area_code.R4-4", value: "R4-4" },
			{ display: "dealer.service_area_code.R5-1", value: "R5-1" },
			{ display: "dealer.service_area_code.R5-2", value: "R5-2" },
			{ display: "dealer.service_area_code.R5-3", value: "R5-3" },
		]
	},
	{
		label: "dealer.dealer_region", name: "dealer.dealer_region", type: "dropdown", required: true, margin: "normal", className: "col-6 col-lg-3 col-md-6 col-sm-6 pr-2 mt-0 mb-2",
		default: { display: "dealer.dealer_region.all", value: "all" },
		options: [
			{ display: "dealer.dealer_region.G.BANGKOK", value: "G.BANGKOK" },
			{ display: "dealer.dealer_region.CENTRAL", value: "CENTRAL" },
			{ display: "dealer.dealer_region.EAST", value: "EAST" },
			{ display: "dealer.dealer_region.NORTH", value: "NORTH" },
			{ display: "dealer.dealer_region.NORTH-EAST", value: "NORTH-EAST" },
			{ display: "dealer.dealer_region.SOUTH", value: "SOUTH" },
		]
	},
	{
		label: "dealer.dealer_name", name: "dealer_id", type: "autocomplete", required: false, margin: "normal", className: "col-6 col-lg-9 col-md-6 col-sm-6 pr-2 mt-0 mb-2",
		default: { display: "dealer.dealer_name.all", value: "all" },
		options: []
	}
]

export const ticketBpByDealerSearchFormFields = [
	{
		label: "quarter", name: "quarter", type: "dropdown", required: true, margin: "normal", className: "col-6 col-lg-_3col-md-3 col-sm-3 pr-2 mt-0 mb-2",
		default: { display: "quarter.all", value: "all" },
		options: [
			{ display: "quarter.1", value: "2" },
			{ display: "quarter.2", value: "3" },
			{ display: "quarter.3", value: "4" },
			{ display: "quarter.4", value: "1" },
		]
	},
	{
		label: "year", name: "year", type: "dropdown", required: true, margin: "normal", className: "col-6 col-lg-3 col-md-3 col-sm-3 pr-2 mt-0 mb-2",
		options: [
			{ display: "year.2024", value: "2024" },
			{ display: "year.2023", value: "2023" },
		]
	},
	{
		label: "dealer.dealer_name", name: "dealer_id", type: "autocomplete", required: false, margin: "normal", className: "col-12 col-lg-6 col-md-6 col-sm-6 pr-2 mt-0 mb-2",
		default: { display: "dealer.dealer_name.all", value: "all" },
		options: []
	}
]

export const ticketBpFormFields = [
	{ label: "ticket.info.customer", type: "header" },
	{ label: "ticket.title", name: "title", type: "text", required: false, margin: "normal", className: "col-6 col-lg-3 col-md-6 col-sm-6 pr-2 mt-0 mb-2" },
	{ label: "ticket.name", name: "name", type: "text", required: true, margin: "normal", className: "col-6 col-lg-3 col-md-6 col-sm-6 pr-2 mt-0 mb-2" },
	{ label: "ticket.age", name: "age", type: "number", required: false, margin: "normal", className: "col-6 col-lg-3 col-md-6 col-sm-6 pr-2 mt-0 mb-2", min: 1 },
	{ label: "ticket.email", name: "email", type: "email", required: false, margin: "normal", className: "col-6 col-lg-3 col-md-6 col-sm-6 pr-2 mt-0 mb-2" },
	{ label: "ticket.tel", name: "tel", type: "text", required: true, margin: "normal", className: "col-6 col-lg-3 col-md-6 col-sm-6 pr-2 mt-0 mb-2" },
	{ label: "ticket.address", name: "address", type: "text", required: false, margin: "normal", className: "col-12 col-lg-9 pr-2 mt-0 mb-2" },
	{ label: "ticket.info.car", type: "header", className: "mt-3" },
	{ label: "ticket.car_model", name: "car_model", type: "text", disabled: true, required: false, margin: "normal", className: "col-6 col-lg-3 col-md-6 col-sm-6 pr-2 mt-0 mb-2" },
	{ label: "ticket.chassis_number", name: "chassis_number", type: "text", disabled: true, required: false, margin: "normal", className: "col-6 col-lg-3 col-md-6 col-sm-6 pr-2 mt-0 mb-2" },
	{ label: "ticket.body_color", name: "body_color", type: "text", disabled: true, required: false, margin: "normal", className: "col-6 col-lg-3 col-md-6 col-sm-6 pr-2 mt-0 mb-2" },
	{ label: "ticket.registration_number", name: "registration_number", type: "text", disabled: true, required: false, margin: "normal", className: "col-6 col-lg-3 col-md-6 col-sm-6 pr-2 mt-0 mb-2" },
	{ label: "ticket.delivered_date", name: "delivered_date", type: "text", disabled: true, required: false, margin: "normal", className: "col-4 pr-2 mt-0 mb-2" },
	{ label: "ticket.job_id", name: "job_id", type: "text", disabled: true, required: false, margin: "normal", className: "col-4 pr-2 mt-0 mb-2" },
	{ label: "ticket.staff_name", name: "staff_name", type: "text", disabled: true, required: false, margin: "normal", className: "col-4 pr-2 mt-0 mb-2" },
	{ label: "ticket.complaint_categories", type: "header", className: "mt-3" },
	{ label: "ticket.complaint_categories.A", type: "header", className: "mt-3" },
	{ label: "ticket.complaint_categories.A01", name: "complaint_categories.A01", type: "checkbox", className: "col-12 col-sm-6 col-lg-4 pr-2 mt-0 mb-2" },
	{ label: "ticket.complaint_categories.A02", name: "complaint_categories.A02", type: "checkbox", className: "col-12 col-sm-6 col-lg-4 pr-2 mt-0 mb-2" },
	{ label: "ticket.complaint_categories.A03", name: "complaint_categories.A03", type: "checkbox", className: "col-12 col-sm-6 col-lg-4 pr-2 mt-0 mb-2" },
	{ label: "ticket.complaint_categories.A05", name: "complaint_categories.A05", type: "checkbox", className: "col-12 col-sm-6 col-lg-4 pr-2 mt-0 mb-2" },
	{ label: "ticket.complaint_categories.A06", name: "complaint_categories.A06", type: "checkbox", className: "col-12 col-sm-6 col-lg-4 pr-2 mt-0 mb-2" },
	{ label: "ticket.complaint_categories.A07", name: "complaint_categories.A07", type: "checkbox", className: "col-12 col-sm-6 col-lg-4 pr-2 mt-0 mb-2" },
	{ label: "ticket.complaint_categories.A99", name: "complaint_categories.A99", type: "checkbox", className: "col-12 col-sm-6 col-lg-12 pr-2 mt-0 mb-0" },
	{ label: "ticket.complaint_categories.A100", name: "complaint_categories.A100", type: "text", className: "col-12 col-sm-6 col-lg-4 pr-2 mt-0 mb-2" },
	{ label: "ticket.complaint_categories.F", type: "header", className: "mt-3" },
	{ label: "ticket.complaint_categories.F99", name: "complaint_categories.F99", type: "checkbox", className: "col-12 col-sm-6 col-lg-12 pr-2 mt-0 mb-0" },
	{ label: "ticket.complaint_categories.F100", name: "complaint_categories.F100", type: "text", className: "col-12 col-sm-6 col-lg-4 pr-2 mt-0 mb-2" },
	{ label: "ticket.complaint_categories.P", type: "header", className: "mt-3" },
	{ label: "ticket.complaint_categories.P01", name: "complaint_categories.P01", type: "checkbox", className: "col-12 col-sm-12 col-lg-4 pr-2 mt-0 mb-2" },
	{ label: "ticket.complaint_categories.P99", name: "complaint_categories.P99", type: "checkbox", className: "col-12 col-sm-6 col-lg-12 pr-2 mt-0 mb-0" },
	{ label: "ticket.complaint_categories.P100", name: "complaint_categories.P100", type: "text", className: "col-12 col-sm-6 col-lg-4 pr-2 mt-0 mb-2" },
	{ label: "ticket.complaint_description", name: "complaint_description", type: "textarea", disabled: true, required: false, multiline: true, rows: 3, rowsMax: 5, margin: "normal", className: "col-12  pr-2 mt-0 mb-2" },
	{ label: "ticket.q1", name: "q1", type: "header", className: "mt-3" },
	{ label: "ticket.q2", name: "q2", type: "text", disabled: true, required: false, className: "col-12  pr-2 mt-0 mb-2" },
	{ label: "ticket.complaint_solution", name: "complaint_solution", type: "textarea", required: true, multiline: true, rows: 3, rowsMax: 5, margin: "normal", className: "col-12  pr-2 mt-0 mb-2" },
	{ label: "dealer", type: "header", className: "mt-3" },
	{ label: "dealer.dealer_name", name: "dealer.dealer_name", type: "text", disabled: true, required: false, margin: "normal", className: "col-12 col-md-6 col-lg-6  pr-2 mt-0 mb-2" },
	{ label: "dealer.branch_name", name: "dealer.branch_name", type: "text", disabled: true, required: false, margin: "normal", className: "col-12 col-md-6 col-lg-6  pr-2 mt-0 mb-2" },
	{ label: "dealer.bp_manager_name", name: "dealer.bp_manager_name", type: "text", disabled: true, required: false, margin: "normal", className: "col-12  pr-2 mt-0 mb-2" },
	{
		label: "ticket.status", name: "status", type: "dropdown", required: false, margin: "normal", className: "col-12 col-lg-4 col-md-6 col-sm-6 pr-2 mt-0 mb-2",
		options: []
	},
]

export const ticketStatusBpOptions = [
	{ display: "ticket.status.W", value: "W" },
	{ display: "ticket.status.C", value: "C" },
	{ display: "ticket.status.S", value: "S" },
	{ display: "ticket.status.T", value: "T" },
	{ display: "ticket.status.B", value: "B" }
]

export const userSearchFormFields = [
	{ label: "user.first_name", name: "first_name", type: "text", required: false, margin: "normal", className: "col-6 col-lg-3 col-md-6 col-sm-6 pr-2 mt-0 mb-2" },
	{ label: "user.last_name", name: "last_name", type: "text", required: false, margin: "normal", className: "col-6 col-lg-3 col-md-6 col-sm-6 pr-2 mt-0 mb-2" },
	{ label: "user.email", name: "email", type: "text", required: false, margin: "normal", className: "col-6 col-lg-3 col-md-6 col-sm-6 pr-2 mt-0 mb-2" },
	{
		label: "user.type", name: "type", type: "dropdown", required: false, margin: "normal", className: "col-6 col-lg-3 col-md-6 col-sm-6 pr-2 mt-0 mb-2",
		options: [
			{ display: "user.type.nissan", value: "nissan" },
			{ display: "user.type.nml", value: "nml" },
			{ display: "user.type.region_manager", value: "region_manager" },
			{ display: "user.type.area_manager", value: "area_manager" },
			{ display: "user.type.csn", value: "csn" },
			{ display: "user.type.dealer", value: "dealer" },
		]
	},
]

export const userFormFields = [
	{ label: "user.first_name", name: "first_name", type: "text", required: true, margin: "normal", className: "col-6 col-lg-3 col-md-6 col-sm-6 pr-2 mt-0 mb-2" },
	{ label: "user.last_name", name: "last_name", type: "text", required: true, margin: "normal", className: "col-6 col-lg-3 col-md-6 col-sm-6 pr-2 mt-0 mb-2" },
	{ label: "user.email", name: "email", type: "text", required: true, margin: "normal", className: "col-6 col-lg-3 col-md-6 col-sm-6 pr-2 mt-0 mb-2" },
	{ label: "user.password", name: "password", type: "text", required: true, margin: "normal", className: "col-6 col-lg-3 col-md-6 col-sm-6 pr-2 mt-0 mb-2" },
	{ label: "user.mobile", name: "mobile", type: "text", required: false, margin: "normal", className: "col-6 col-lg-3 col-md-6 col-sm-6 pr-2 mt-0 mb-2" },
	{ label: "user.position_title", name: "position_title", type: "text", required: false, margin: "normal", className: "col-6 col-lg-3 col-md-6 col-sm-6 pr-2 mt-0 mb-2" },
	{
		label: "user.active_flag", name: "active_flag", type: "dropdown", required: true, margin: "normal", className: "col-6 col-lg-3 col-md-6 col-sm-6 pr-2 mt-0 mb-2",
		options: [
			{ display: "user.active_flag.1", value: "1" },
			{ display: "user.active_flag.0", value: "0" },
		]
	},
	{
		label: "user.type", name: "type", type: "dropdown", required: true, margin: "normal", className: "col-6 col-lg-3 col-md-6 col-sm-6 pr-2 mt-0 mb-2",
		options: [
			{ display: "user.type.nissan", value: "nissan" },
			{ display: "user.type.nml", value: "nml" },
			{ display: "user.type.region_manager", value: "region_manager" },
			{ display: "user.type.area_manager", value: "area_manager" },
			{ display: "user.type.csn", value: "csn" },
		]
	},
	{ label: "user.group", type: "header", className: "mt-3" },
	{ label: "user.group.showroom", name: "group.showroom", type: "checkbox", className: "col-12 col-sm-6 col-lg-6 pr-2 mt-0 mb-2" },
	{ label: "user.group.service", name: "group.service", type: "checkbox", className: "col-12 col-sm-6 col-lg-6 pr-2 mt-0 mb-2" },
	{
		label: "dealer.dealer_sales_region", name: "dealer_sales_region", type: "dropdown", required: true, margin: "normal", className: "col-6  col-md-6 col-sm-6 pr-2 mt-0 mb-2",
		default: { display: "dealer.dealer_sales_region.all", value: "all" },
		options: [
			{ display: "dealer.dealer_sales_region.Region1", value: "REGION 1" },
			{ display: "dealer.dealer_sales_region.Region2", value: "REGION 2" },
			{ display: "dealer.dealer_sales_region.Region3", value: "REGION 3" },
			{ display: "dealer.dealer_sales_region.Region4", value: "REGION 4" },
			{ display: "dealer.dealer_sales_region.Region5", value: "REGION 5" }
		]
	},
	{
		label: "dealer.dealer_service_region", name: "dealer_service_region", type: "dropdown", required: true, margin: "normal", className: "col-6  col-md-6 col-sm-6 pr-2 mt-0 mb-2",
		default: { display: "dealer.dealer_service_region.all", value: "all" },
		options: [
			{ display: "dealer.dealer_service_region.Region1", value: "REGION 1" },
			{ display: "dealer.dealer_service_region.Region2", value: "REGION 2" },
			{ display: "dealer.dealer_service_region.Region3", value: "REGION 3" },
			{ display: "dealer.dealer_service_region.Region4", value: "REGION 4" },
			{ display: "dealer.dealer_service_region.Region5", value: "REGION 5" }
		]
	},
	{
		label: "dealer.sale_area_code", name: "sale_area_code", type: "dropdown", required: true, margin: "normal", className: "col-6  col-md-6 col-sm-6 pr-2 mt-0 mb-2",
		options: [
			{ display: "dealer.sale_area_code.R1-1", value: "R1-1" },
			{ display: "dealer.sale_area_code.R1-2", value: "R1-2" },
			{ display: "dealer.sale_area_code.R1-3", value: "R1-3" },
			{ display: "dealer.sale_area_code.R1-4", value: "R1-4" },
			{ display: "dealer.sale_area_code.R2-1", value: "R2-1" },
			{ display: "dealer.sale_area_code.R2-2", value: "R2-2" },
			{ display: "dealer.sale_area_code.R2-3", value: "R2-3" },
			{ display: "dealer.sale_area_code.R3-1", value: "R3-1" },
			{ display: "dealer.sale_area_code.R3-2", value: "R3-2" },
			{ display: "dealer.sale_area_code.R3-3", value: "R3-3" },
			{ display: "dealer.sale_area_code.R4-1", value: "R4-1" },
			{ display: "dealer.sale_area_code.R4-2", value: "R4-2" },
			{ display: "dealer.sale_area_code.R4-3", value: "R4-3" },
			{ display: "dealer.sale_area_code.R5-1", value: "R5-1" },
			{ display: "dealer.sale_area_code.R5-2", value: "R5-2" },
			{ display: "dealer.sale_area_code.R5-3", value: "R5-3" },
		]
	},
	{
		label: "dealer.service_area_code", name: "service_area_code", type: "dropdown", required: true, margin: "normal", className: "col-6 col-md-6 col-sm-6 pr-2 mt-0 mb-2",
		options: [
			{ display: "dealer.service_area_code.R1-1", value: "R1-1" },
			{ display: "dealer.service_area_code.R1-2", value: "R1-2" },
			{ display: "dealer.service_area_code.R1-3", value: "R1-3" },
			{ display: "dealer.service_area_code.R1-4", value: "R1-4" },
			{ display: "dealer.service_area_code.R2-1", value: "R2-1" },
			{ display: "dealer.service_area_code.R2-2", value: "R2-2" },
			{ display: "dealer.service_area_code.R2-3", value: "R2-3" },
			{ display: "dealer.service_area_code.R2-4", value: "R2-4" },
			{ display: "dealer.service_area_code.R3-1", value: "R3-1" },
			{ display: "dealer.service_area_code.R3-2", value: "R3-2" },
			{ display: "dealer.service_area_code.R3-3", value: "R3-3" },
			{ display: "dealer.service_area_code.R4-1", value: "R4-1" },
			{ display: "dealer.service_area_code.R4-2", value: "R4-2" },
			{ display: "dealer.service_area_code.R4-3", value: "R4-3" },
			{ display: "dealer.service_area_code.R4-4", value: "R4-4" },
			{ display: "dealer.service_area_code.R5-1", value: "R5-1" },
			{ display: "dealer.service_area_code.R5-2", value: "R5-2" },
			{ display: "dealer.service_area_code.R5-3", value: "R5-3" },
		]
	},
	{ label: "user.permission", type: "header", className: "mt-3" },
	{ label: "user.permission.ticket_validation", name: "permission.ticket_validation", type: "checkbox", className: "col-12 col-sm-6 col-lg-4 pr-2 mt-0 mb-2" },
	{ label: "user.permission.ticket_validation_closed_edit", name: "permission.ticket_validation_closed_edit", type: "checkbox", className: "col-12 col-sm-6 col-lg-4 pr-2 mt-0 mb-2" },
	{ label: "user.permission.bp", type: "header", className: "mt-3" },
	{ label: "user.permission.bp_dashboard", name: "permission.bp_dashboard", type: "checkbox", className: "col-12 col-sm-6 col-lg-4 pr-2 mt-0 mb-2" },
	{ label: "user.permission.bp_report", name: "permission.bp_report", type: "checkbox", className: "col-12 col-sm-6 col-lg-4 pr-2 mt-0 mb-2" },

]