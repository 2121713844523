import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
	REPORT_RESPONSE_RATE_FETCH,
	REPORT_RESPONSE_RATE_DAILY_FETCH,
	REPORT_SUMMARY_FETCH,
	REPORT_SUMMARY_DEALER_FETCH,
	REPORT_CUSTOMER_SURVEY_DETAIL_FETCH,
	REPORT_CUSTOMER_SURVEY_DETAIL_NOTEPAD_FETCH
} from "../actions/types";
import {
	reportResponseRateFetchSuccess,
	reportResponseRateDailyFetchSuccess,
	reportSummaryDealerFetchSuccess,
	reportSummaryFetchSuccess,
	reportCustomerSurveyDetailFetchSuccess,
	reportCustomerSurveyDetailNotepadFetchSuccess
} from "../actions/ReportActions";
import { showErrorMessage, hideLoader } from "../actions/NotifyActions";
import { authorizationHeader, getErrorMessage } from "../util/apiUtil";
import axios from "axios";
import FileSaver from "file-saver";
import moment from "moment";
import _ from "lodash";

// API
export const reportResponseRateFetchRequest = async payload => {
	let url = `${process.env.REACT_APP_API_SURVEY_URL}/api/responserate/`;
	let params = { ...payload };
	let auth = { username: process.env.REACT_APP_API_SURVEY_USER, password: process.env.REACT_APP_API_SURVEY_PASSWORD };
	params = _.omitBy(params, _.isEmpty);

	return await axios
		.post(url, params, { auth })
		.then(response => response.data)
		.catch(error => {
			throw error;
		});
}

export const reportResponseRateDailyFetchRequest = async payload => {
	let url = `${process.env.REACT_APP_API_SURVEY_URL}/api/responserate-day/`;
	let params = { ...payload };
	let auth = { username: process.env.REACT_APP_API_SURVEY_USER, password: process.env.REACT_APP_API_SURVEY_PASSWORD };
	params = _.omitBy(params, _.isEmpty);

	return await axios
		.post(url, params, { auth })
		.then(response => response.data)
		.catch(error => {
			throw error;
		});
}

export const reportSummaryDealerFetchRequest = async payload => {
	let url = `${process.env.REACT_APP_API_SURVEY_URL}/api/summary/`;
	let params = { ...payload };
	let auth = { username: process.env.REACT_APP_API_SURVEY_USER, password: process.env.REACT_APP_API_SURVEY_PASSWORD };
	params = _.omitBy(params, _.isEmpty);

	return await axios
		.post(url, params, { auth })
		.then(response => response.data)
		.catch(error => {
			throw error;
		});
}

export const reportSummaryFetchRequest = async payload => {
	let url = `${process.env.REACT_APP_API_SURVEY_URL}/api/xport-quarterly/`;
	let params = { ...payload };
	let auth = { username: process.env.REACT_APP_API_SURVEY_USER, password: process.env.REACT_APP_API_SURVEY_PASSWORD };
	params = _.omitBy(params, _.isEmpty);
	return await axios
		.post(url, params, { auth })
		.then(response => response.data)
		.catch(error => {
			throw error;
		});
}

export const reportCustomerSurveyDetailFetchRequest = async payload => {
	let url = `${process.env.REACT_APP_API_SURVEY_URL}/api/rawdata/`;
	let params = { ...payload };
	let auth = { username: process.env.REACT_APP_API_SURVEY_USER, password: process.env.REACT_APP_API_SURVEY_PASSWORD };
	params = _.omitBy(params, _.isUndefined);

	return await axios
		.post(url, params, { auth })
		.then(response => response.data)
		.catch(error => {
			throw error;
		});
}

export const reportCustomerSurveyDetailNotepadFetchRequest = async payload => {
	let url = `${process.env.REACT_APP_API_SURVEY_URL}/api/rawdata-notepad/`;
	let params = { ...payload };
	let auth = { username: process.env.REACT_APP_API_SURVEY_USER, password: process.env.REACT_APP_API_SURVEY_PASSWORD };
	params = _.omitBy(params, _.isEmpty);

	return await axios
		.post(url, params, { auth })
		.then(response => response.data)
		.catch(error => {
			throw error;
		});
}

// WORKER
function* reportResponseRateFetch({ payload }) {
	try {
		const data = yield call(reportResponseRateFetchRequest, payload);
		console.log(data);
		if (data.success) {
			yield put(reportResponseRateFetchSuccess({ ...data.data, filter: payload }));
		}
		yield put(hideLoader());
	} catch (error) {
		yield put(showErrorMessage(getErrorMessage(error)));
	}
}

function* reportResponseRateDailyFetch({ payload }) {
	try {
		const data = yield call(reportResponseRateDailyFetchRequest, payload);
		console.log(data);
		if (data.success) {
			yield put(reportResponseRateDailyFetchSuccess({ ...data.data, filter: payload }));
		}
		yield put(hideLoader());
	} catch (error) {
		yield put(showErrorMessage(getErrorMessage(error)));
	}
}

function* reportSummaryFetch({ payload }) {
	try {
		const data = yield call(reportSummaryFetchRequest, payload)
		console.log(data)
		if (data.success) {
			yield put(reportSummaryFetchSuccess({ ...data.data }));
		}
		yield put(hideLoader());
	} catch (error) {
		yield put(showErrorMessage(getErrorMessage(error)));
	}
}

function* reportSummaryDealerFetch({ payload }) {
	try {
		const data = yield call(reportSummaryDealerFetchRequest, payload)
		console.log(data)
		if (data.success) {
			yield put(reportSummaryDealerFetchSuccess({ ...data, filter: payload }));
		}
		yield put(hideLoader());
	} catch (error) {
		yield put(showErrorMessage(getErrorMessage(error)));
	}
}

function* reportCustomerSurveyDetailFetch({ payload }) {
	try {
		const data = yield call(reportCustomerSurveyDetailFetchRequest, payload);
		console.log(data)
		if (data.success) {
			yield put(reportCustomerSurveyDetailFetchSuccess({ ...data.data, filter: payload }));
		}
		yield put(hideLoader());
	} catch (error) {
		yield put(showErrorMessage(getErrorMessage(error)));
	}
}

function* reportCustomerSurveyDetailNotepadFetch({ payload }) {
	try {
		const data = yield call(reportCustomerSurveyDetailNotepadFetchRequest, payload);
		console.log(data);
		if (data.success) {
			yield put(reportCustomerSurveyDetailNotepadFetchSuccess( data.data ));
		}
		yield put(hideLoader());
	} catch (error) {
		yield put(showErrorMessage(getErrorMessage(error)));
	}
}

// WATCHER
function* reportResponseRateFetchWatcher() {
	yield takeEvery(REPORT_RESPONSE_RATE_FETCH, reportResponseRateFetch);
}

function* reportResponseRateDailyFetchWatcher() {
	yield takeEvery(REPORT_RESPONSE_RATE_DAILY_FETCH, reportResponseRateDailyFetch);
}

function* reportSummaryFetchWatcher() {
	yield takeEvery(REPORT_SUMMARY_FETCH, reportSummaryFetch);
}

function* reportSummaryDealerFetchWatcher() {
	yield takeEvery(REPORT_SUMMARY_DEALER_FETCH, reportSummaryDealerFetch);
}

function* reportCustomerSurveyDetailFetchWatcher() {
	yield takeEvery(REPORT_CUSTOMER_SURVEY_DETAIL_FETCH, reportCustomerSurveyDetailFetch);
}

function* reportCustomerSurveyDetailNotepadFetchWatcher() {
	yield takeEvery(REPORT_CUSTOMER_SURVEY_DETAIL_NOTEPAD_FETCH, reportCustomerSurveyDetailNotepadFetch);
}

export default function* rootSaga() {
	yield all([
		fork(reportResponseRateFetchWatcher),
		fork(reportResponseRateDailyFetchWatcher),
		fork(reportSummaryFetchWatcher),
		fork(reportSummaryDealerFetchWatcher),
		fork(reportCustomerSurveyDetailFetchWatcher),
		fork(reportCustomerSurveyDetailNotepadFetchWatcher)
	]);
}