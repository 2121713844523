import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Button from '@material-ui/core/Button';
import { USER_TYPES, CALLCENTER_TYPES, AREA_MANAGER_TYPES, NML_TYPES, ADMIN_TYPES, DEALER_TYPES, REGION_MANAGER_TYPES } from "../../constants/Systems";
import IntlMessages from "util/IntlMessages";
import CustomScrollbars from "util/CustomScrollbars";
import {
	isDealerPermissionGeneralReport,
	isDealerPermissionManagementReport,
	isDealerPermissionTicketBpView
} from "../../util/authUtil";

class SidenavContent extends Component {

	componentDidMount() {
		const { history } = this.props;
		const that = this;
		const pathname = `${history.location.pathname}`; // get current path
		console.log(pathname);
		const menuLi = document.getElementsByClassName("menu");
		for (let i = 0; i < menuLi.length; i++) {
			menuLi[i].onclick = function (event) {
				for (let j = 0; j < menuLi.length; j++) {
					const parentLi = that.closest(this, "li");
					if (menuLi[j] !== this && (parentLi === null || !parentLi.classList.contains("open"))) {
						menuLi[j].classList.remove("open");
					}
				}
				this.classList.toggle("open");
			};
		}

		const activeLi = document.querySelector('a[href="' + pathname + '"]'); // select current a element
		try {
			const activeNav = this.closest(activeLi, "ul"); // select closest ul
			if (activeNav.classList.contains("sub-menu")) {
				this.closest(activeNav, "li").classList.add("open");
			} else {
				this.closest(activeLi, "li").classList.add("open");
			}
		} catch (error) { }
	}

	componentDidUpdate() {
		const { history } = this.props;
		const that = this;
		const pathname = `${history.location.pathname}`; // get current path

		const menuLi = document.getElementsByClassName("menu");
		for (let i = 0; i < menuLi.length; i++) {
			menuLi[i].onclick = function (event) {
				for (let j = 0; j < menuLi.length; j++) {
					const parentLi = that.closest(this, "li");
					if (menuLi[j] !== this && (parentLi === null || !parentLi.classList.contains("open"))) {
						menuLi[j].classList.remove("open");
					}
				}
				this.classList.toggle("open");
			};
		}

		const activeLi = document.querySelector('a[href="' + pathname + '"]'); // select current a element
		try {
			const activeNav = this.closest(activeLi, "ul"); // select closest ul
			if (activeNav.classList.contains("sub-menu")) {
				this.closest(activeNav, "li").classList.add("open");
			} else {
				this.closest(activeLi, "li").classList.add("open");
			}
		} catch (error) { }
	}

	closest(el, selector) {
		try {
			let matchesFn;
			// find vendor prefix
			["matches", "webkitMatchesSelector", "mozMatchesSelector", "msMatchesSelector", "oMatchesSelector"].some(function (
				fn
			) {
				if (typeof document.body[fn] == "function") {
					matchesFn = fn;
					return true;
				}
				return false;
			});

			let parent;

			// traverse parents
			while (el) {
				parent = el.parentElement;
				if (parent && parent[matchesFn](selector)) {
					return parent;
				}
				el = parent;
			}
		} catch (e) { }

		return null;
	}

	render() {
		const { authUser } = this.props;

		return (
			<CustomScrollbars className=" scrollbar">
				<ul className="nav-menu">
					<li className="menu no-arrow">
						<NavLink to="/app/main">
							<span className="nav-text text-uppercase">
								<IntlMessages id="main" />
							</span>
						</NavLink>
					</li>

					{authUser && (USER_TYPES.includes(authUser.type) || REGION_MANAGER_TYPES.includes(authUser.type) || AREA_MANAGER_TYPES.includes(authUser.type) ||
						(DEALER_TYPES.includes(authUser.type) && isDealerPermissionTicketBpView(authUser))) &&
						(
							<React.Fragment>
								<li className="menu no-arrow">
									<NavLink to="/app/report/responserate/daily">
										<span className="nav-text text-uppercase">
											<IntlMessages id="report.responserate.daily" />
										</span>
									</NavLink>
								</li>
								<li className="menu no-arrow">
									<NavLink to="/app/report/responserate">
										<span className="nav-text text-uppercase">
											<IntlMessages id="report.responserate.quarter" />
										</span>
									</NavLink>
								</li>
								<li className="menu no-arrow">
									<NavLink to="/app/ticket/survey/bp">
										<span className="nav-text text-uppercase">
											<IntlMessages id="ticket" />
										</span>
									</NavLink>
								</li>
								<li className="menu no-arrow">
									<NavLink to="/app/report/summary/dealer">
										<span className="nav-text text-uppercase">
											<IntlMessages id="report.summary.dealer" />
										</span>
									</NavLink>
								</li>
								{authUser && (USER_TYPES.includes(authUser.type) || REGION_MANAGER_TYPES.includes(authUser.type) || AREA_MANAGER_TYPES.includes(authUser.type)) && (
									<li className="menu no-arrow">
										<NavLink to="/app/report/summary">
											<span className="nav-text text-uppercase">
												<IntlMessages id="report.summary" />
											</span>
										</NavLink>
									</li>
								)}
								<li className="menu no-arrow">
									<NavLink to="/app/report/customer/survey/detail">
										<span className="nav-text text-uppercase">
											<IntlMessages id="report.customer.survey.detail" />
										</span>
									</NavLink>
								</li>
								{authUser && USER_TYPES.includes(authUser.type) && (
									<li className="menu no-arrow">
										<NavLink to="/app/dealer">
											<span className="nav-text text-uppercase">
												<IntlMessages id="dealer" />
											</span>
										</NavLink>
									</li>
								)}
								{authUser && ADMIN_TYPES.includes(authUser.type) && (
									<li className="menu no-arrow">
										<NavLink to="/app/user">
											<span className="nav-text text-uppercase">
												<IntlMessages id="user" />
											</span>
										</NavLink>
									</li>
								)}
							</React.Fragment>
						)}
				</ul>
			</CustomScrollbars>
		);
	}
}

const mapStateToProps = ({ auth: { authUser } }) => {
	return { authUser };
};

export default withRouter(
	connect(
		mapStateToProps,
		{}
	)(SidenavContent)
);
