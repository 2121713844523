import React from "react";
import { Field } from "redux-form";
import FormField from "../components/form/FormField";
import IntlMessages from "util/IntlMessages";
import moment from "moment";


export const renderFields = formFields => {
	return formFields.map(({ name, label, ...rest }) => {
		return (
			<React.Fragment key={label}>
				{name && <Field label={<IntlMessages id={label} />} name={name} component={FormField} {...rest} />}
				{!name && <FormField label={<IntlMessages id={label} />}   {...rest} />}
			</React.Fragment>
		);
	});
};

export const insertOptions = (formFields, name, arr, value, display, intl = false) => {
	let options = [];
	if (arr && arr.length > 0) {
		arr.map(element => {
			options.push({ display: element[display], value: element[value], intl });
		});
	} 
	formFields.forEach(element => {
		if (element.name === name) {
			element.options = options;
		}
	});
	return formFields;
};

export const getYearRound = (date) => {
	return  moment(date).month() < 4 ? moment(date).year() - 1 : moment(date).year() ;
}

export const validateField = (method, value) => {
	switch (method) {
		case "email":
			return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
				value
			);
		case "url":
			return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})).?)(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
				value
			);
	}
};
